import { combineReducers } from "redux";

// Local reducers
import form from "./form";
import enumerations from "./enumerations";
import contract from "./contract";
import user from "./user";
import products from "./products";
import routing from "./routing";
import error from "./error";
import pdf from "./pdf";
import newTransfer from "./newTransfer";
import lead from "./lead";

const rootReducer = combineReducers({
  form,
  enumerations,
  contract,
  user,
  products,
  routing,
  pdf,
  newTransfer,
  error,
  lead,
});

export default rootReducer;
