import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Field, WrappedFieldProps } from "redux-form";
import classNames from "classnames";
import locs from "../../localization";
import Input from "../../components/Input";
import Autosuggest from "../../components/Autosuggest";
import styles from "./styles.module.scss";
import inputStyles from "../../components/Input/styles.module.scss";
import formValidation from "../../validations/formValidation";
import isNumber from "../../validations/isNumber";
import isIn from "../../validations/isIn";
import isLength from "../../validations/isLength";
import { getEnumerationData } from "../../reducers/enumerations";
import { ENUMERATION_BANK } from "../../constants/enumerations";
import { compose } from "redux";
import { Bank } from "../../constants/interfaces";
import isNotEmpty from "../../validations/isNotEmpty";

const normalizeNumber = value => value && value.replace(/[^0-9]/g, "");

export interface BankAccountProps {
  label?: string;
  disabled?: boolean;
  theme?: any;
}

function mapStateToProps(state) {
  return {
    banks: getEnumerationData<Bank>(state.enumerations, ENUMERATION_BANK),
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function BankAccount(props: BankAccountProps & PropsFromRedux & WrappedFieldProps) {
  const {
    banks,
    input: { name },
    label,
    theme = {},
    meta,
    disabled,
  } = props;

  const labelClassNames = classNames(theme.label, styles.label);

  const isInvalid = () => {
    // @ts-ignore
    if (meta.number && meta.bankCode) {
      // @ts-ignore
      return meta.invalid && meta.number.touched && meta.bankCode.touched;
    }

    return false;
  };

  const bankAccountTheme = container => {
    return {
      baseContainer: container,
      ...theme,
      input: isInvalid() && inputStyles.inputInvalid,
    };
  };

  let errorMessage = "";
  if (isInvalid()) {
    errorMessage = typeof meta.error === "string" ? meta.error : "";
  }

  return (
    <div>
      <div className={styles.container}>
        {label && <div className={labelClassNames}>{label}</div>}
        <div className={styles.bankAccount}>
          <div className={styles.fieldsContainer}>
            <Field
              name={`${name}.prefix`}
              type="text"
              theme={{
                inputContainer: styles.prefix,
                ...bankAccountTheme(styles.prefixContainer),
              }}
              placeholder={locs("placeholders.bankPrefix")}
              component={Input}
              disabled={disabled}
              validate={!disabled && [formValidation(isNumber), formValidation(isLength({ max: 10 }))]}
              normalize={normalizeNumber}
            />
            <span className={styles.separator}>-</span>
            <Field
              name={`${name}.number`}
              type="text"
              theme={{
                inputContainer: styles.account,
                ...bankAccountTheme(styles.accountContainer),
              }}
              placeholder={locs("placeholders.bankAccount")}
              component={Input}
              disabled={disabled}
              validate={
                !disabled && [formValidation(isNotEmpty), formValidation(isNumber), formValidation(isLength({ max: 20 }))]
              }
              normalize={normalizeNumber}
            />
            <span className={styles.separator}>/</span>
            <Field
              name={`${name}.bankCode`}
              type="text"
              theme={{
                inputContainer: styles.bankCode,
                ...bankAccountTheme(styles.bankCodeContainer),
              }}
              placeholder={locs("placeholders.bankCode")}
              component={Autosuggest}
              disabled={disabled}
              validate={
                !disabled && [
                  formValidation(isNotEmpty),
                  formValidation(isNumber),
                  formValidation(isLength({ max: 5 })),
                  formValidation(isIn({ values: banks.map(x => x.value) }), locs("errors.invalidBankCode")),
                ]
              }
              normalize={normalizeNumber}
              suggestionsResolve={val => {
                const reg = new RegExp(val);
                return banks.filter(bank => reg.test(bank.value));
              }}
              suggestionDelay={0}
              suggestionThreshold={1}
              getSuggestionValue={bank => bank.value}
              renderSuggestion={bank => <div>{`${bank.value} - ${bank.desc}`}</div>}
              dynamicAlignment={true} // Enable CSS class to align to right on mobile
            />
          </div>
          {isInvalid() && <div className={classNames(inputStyles.errorMessage, styles.errorMessage)}>{errorMessage}</div>}
        </div>
      </div>
    </div>
  );
}

export default compose(connector)(BankAccount);
