import React from "react";
import { calculateConsumption, ConsumptionInput } from "../../helpers/calculateConsumption";
import Input from "../Input";
import styles from "./styles.module.scss";

interface ConsumptionInfoProps extends ConsumptionInput {
  locs: (key: string) => string;
  electricity: boolean;
}

const ConsumptionInfo: React.FC<ConsumptionInfoProps> = props => {
  const { estimate, knownConsumption, spConsumption, electricity, locs } = props;

  const { consumption, consumptionHigh, consumptionLow, prefix } = calculateConsumption({
    estimate,
    knownConsumption,
    spConsumption,
    electricity,
  });

  if (consumption <= 0) {
    return null;
  }

  const inputTheme = {
    baseContainer: styles.expected_consumption_base_container,
    label: styles.expected_consumption_input_label,
  };

  return (
    <div className={styles.expected_consumption}>
      <div className={styles.expected_consumption_label}>{prefix}:</div>

      {consumptionHigh > 0 && electricity && (
        <Input
          input={{ value: consumptionHigh, readOnly: true } as any}
          label={locs("labels.high_tariff")}
          suffix={locs("labels.mwh_year")}
          theme={inputTheme}
          meta={{} as any}
          disabled={true}
        />
      )}

      {consumptionLow > 0 && electricity && (
        <Input
          label={locs("labels.low_tariff")}
          input={{ value: consumptionLow, readOnly: true } as any}
          suffix={locs("labels.mwh_year")}
          theme={inputTheme}
          meta={{} as any}
          disabled={true}
        />
      )}

      {consumption > 0 && !electricity && (
        <Input
          input={{ value: consumption } as any}
          suffix={locs("labels.mwh_year")}
          theme={inputTheme}
          meta={{} as any}
          disabled={true}
        />
      )}
    </div>
  );
};

export default ConsumptionInfo;
