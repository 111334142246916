import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

export interface ButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, "size"> {
  type?: "primary" | "secondary";
  size?: "small" | "normal";
  showArrow?: boolean;
}

export default function Button({
  type = "primary",
  size = "normal",
  className,
  children,
  showArrow = false,
  ...rest
}: ButtonProps) {
  const buttonClassName = classNames(
    className,
    styles.button,
    styles[`button__${size}`],
    { [styles.show_arrow]: showArrow },
    { [styles.secondary]: type === "secondary" }
  );

  return (
    <button className={buttonClassName} type="button" {...rest}>
      {children}
    </button>
  );
}
