import React from "react";
import Checkbox from "../../components/Checkbox";
import Title from "../../components/Title";
import locs from "../../localization";
import styles from "./styles.module.scss";

import { PAYMENT_METHOD_BANK_TRANSFER, PERIOD_YEAR } from "../../constants";

import PaymentMethod from "./PaymentMethod";
import { ENUMERATION_PAYMENT_METHOD } from "../../constants/enumerations";
import { getEnumerationData } from "../../reducers/enumerations";
import { PaymentMethod as EnumerationPaymentMethod } from "../../constants/interfaces";
import { connect, ConnectedProps } from "react-redux";
import { Field, InjectedFormProps, WrappedFieldProps, stopSubmit } from "redux-form";
import { compose, Dispatch } from "redux";
import { getPartner } from "../../selectors/contract";
import { CalculatorValues } from "../../reducers/types";
import { productPriceConversion } from "../../helpers/price";
import { ceil50 } from "../../helpers/ceil";

export interface PaymentMethodProps {
  disabled?: boolean;
  input: any;
}

function mapStateToProps(state) {
  return {
    paymentMethods: getEnumerationData<EnumerationPaymentMethod>(state.enumerations, ENUMERATION_PAYMENT_METHOD),
    partner: getPartner(state),
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const handleFormChangePayments = (newValues: CalculatorValues, dispatch: Dispatch<any>, props: InjectedFormProps<any>) => {
  const { change: changeField } = props;

  const {
    payments: {
      paymentMethods: { deposits, depositPaymentTypeForAll },
      paymentsFrequency,
      depositAmount,
    },
    product,
  } = newValues;

  if (!product) {
    return;
  }

  // Payments frequency
  const newDepositAmount = ceil50(productPriceConversion(product.prices, PERIOD_YEAR, paymentsFrequency).total);
  if (depositAmount !== newDepositAmount) {
    dispatch(changeField("payments.depositAmount", newDepositAmount));
  }

  // If payment types differs, always set depositsPaymentTypeForAll false
  if (deposits.paymentType !== PAYMENT_METHOD_BANK_TRANSFER && depositPaymentTypeForAll) {
    dispatch(changeField("payments.paymentMethods.depositPaymentTypeForAll", false));
  }

  if (depositPaymentTypeForAll) {
    dispatch(stopSubmit("calculator", {})); // a hack to clear submit errors from https://github.com/redux-form/redux-form/issues/2604
  }
};

function PaymentMethods(props: PaymentMethodProps & PropsFromRedux & WrappedFieldProps) {
  const {
    input: { value, name },
    disabled,
    partner,
    paymentMethods,
  } = props;

  // @ts-ignore
  const {
    depositPaymentTypeForAll,
    // @ts-ignore
    deposits: { paymentType: depositsPaymentType } = {},
  } = value || {};

  const showExtendedOptions = !depositPaymentTypeForAll;

  // Select proper options for payment method types
  const allowedPaymentMethods = paymentMethods.filter(x => x.partnerId === partner.id && !x.validTo);

  return (
    <div className={styles.paymentMethodsContainer}>
      <Field
        name={`${name}.deposits`}
        type="text"
        component={PaymentMethod}
        props={{
          disabled,
          paymentMethods: allowedPaymentMethods.filter(x => x.advances),
        }}
      />
      {depositsPaymentType === PAYMENT_METHOD_BANK_TRANSFER && (
        <div className={styles.depositPaymentTypeForAllContainer}>
          <Field
            name={`${name}.depositPaymentTypeForAll`}
            type="checkbox"
            component={Checkbox}
            props={{ disabled }}
            label={locs("labels.depositPaymentTypeForAll")}
          />
        </div>
      )}
      {showExtendedOptions && (
        <div className={styles.extendedPaymentsContainer}>
          <Title name={locs("titles.howToPayArrears")} tag="h3" className={styles.title} />
          <Field
            name={`${name}.arrears`}
            type="text"
            component={PaymentMethod}
            props={{
              disabled,
              paymentMethods: allowedPaymentMethods.filter(x => x.underpayments),
            }}
          />
          <Title name={locs("titles.howToRefundOverpayments")} tag="h3" className={styles.howToRefundOverpaymentsTitle} />
          <Field
            name={`${name}.overpayments`}
            type="text"
            component={PaymentMethod}
            props={{
              disabled,
              paymentMethods: allowedPaymentMethods.filter(x => x.overpayments),
            }}
          />
        </div>
      )}
    </div>
  );
}

export default compose(connector)(PaymentMethods) as any;
