import React from "react";
import classNames from "classnames";
import layout from "../UI/styles.module.scss";
import styles from "./styles.module.scss";
import { seleniumClass } from "../../helpers";
import { WrappedFieldProps } from "redux-form";

interface RadioProps {
  label?: string | React.ReactNode;
  disabled?: boolean;
  checked?: boolean;
  highlightLabelOnChecked?: boolean;
  theme?: any;
  help?: React.ReactNode;
}

function Radio({
  input,
  label,
  disabled = false,
  highlightLabelOnChecked = false,
  theme = {},
  help,
}: RadioProps & WrappedFieldProps) {
  let className = "radio";
  if (disabled) {
    className = "radio_disabled";
  } else if (input.checked && highlightLabelOnChecked) {
    className = "radio_checked";
  }

  let labelPart;
  if (typeof label === "string") {
    labelPart = <div className={classNames(styles.radio_label, theme.radio_label)} dangerouslySetInnerHTML={{ __html: label }} />;
  } else {
    labelPart = <div className={classNames(styles.radio_label, theme.radio_label)}>{label}</div>;
  }

  return (
    <div
      className={classNames(
        layout.form_control,
        theme.form_control,
        { [layout.cursorDisabled]: disabled },
        styles[className],
        theme[className]
      )}>
      <label className={seleniumClass(input)}>
        <div className={classNames(styles.radio_input_container, theme.radio_input_container)}>
          <input type="radio" {...input} disabled={disabled} />
          <span />
        </div>
        {labelPart}
      </label>
      {help}
    </div>
  );
}

export default Radio;
