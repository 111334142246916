import getValue from "lodash/get";
import find from "lodash/find";

import { ENUMERATION_FAILURE, ENUMERATION_REQUEST, ENUMERATION_SUCCESS } from "../constants/actions";

import { DATA_FAILURE, DATA_REQUEST, DATA_SUCCESS } from "../constants/async";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ENUMERATION_REQUEST:
      return {
        ...state,
        [action.name]: {
          status: DATA_REQUEST,
        },
      };

    case ENUMERATION_SUCCESS:
      return {
        ...state,
        [action.name]: {
          status: DATA_SUCCESS,
          data: action.payload,
        },
      };

    case ENUMERATION_FAILURE:
      return {
        ...state,
        [action.name]: {
          status: DATA_FAILURE,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

/**
 * Check if all required enumerations are loaded
 *
 * @param {Object} enumerations
 * @param {Array} required
 * @return {Boolean}
 */
export function isEnumerationsReady(enumerations, required = []) {
  return required.reduce((acc, name) => acc && getValue(enumerations, [name, "status"]) === DATA_SUCCESS, true);
}

/**
 * Pull enumeration data from store blob
 *
 * @param {Object} enumerations
 * @param {String} name
 * @return {Array}
 */
export function getEnumerationData<K>(enumerations, name): K[] {
  return getValue(enumerations, [name, "data"], []);
}

export function getEnumerationRecordByValue<K>(enumerations, name, value): K | undefined {
  return find(getEnumerationData(enumerations, name), { value }) as any;
}

export function getEnumerationRecordById<K>(enumerations, name, id): K | undefined {
  return find(getEnumerationData(enumerations, name), { id }) as any;
}
