import React from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { connect, ConnectedProps } from "react-redux";
import locs from "../../localization";

import Container from "../../containers/Container";
import Column from "../../containers/Column";
import Title from "../../components/Title";
import Button from "../../components/Button";
import Link from "../../components/Link";

import ProgressBar from "../../components/ProgressBar";
import Household from "../Contact/Household";
import Company from "../Contact/Company";
import styles from "./styles.module.scss";

import { ENUMERATION_TITLE_PREFIX, ENUMERATION_TITLE_SUFFIX, ENUMERATION_POSITIONS } from "../../constants/enumerations";
import { compose } from "redux";
import { isHousehold, isTransfer, isElectricity, isCrossSell, getCommodity, isNewOmOwnerChange } from "../../selectors/contract";
import { getEnumeration } from "../../selectors/enumerations";
import { Position, TitlePrefix, TitleSuffix } from "../../constants/interfaces";
import { Pages } from "../../lifecycles";
import { fullNameOnChange, validateSameNames } from "../Contact/Person";
import InlineLoader from "../../components/Loader/InlineLoader";

const formKey = "calculator";

function mapStateToProps(state) {
  return {
    /** computed redux values */
    household: isHousehold(state),
    transfer: isTransfer(state),
    newOmOwnerChange: isNewOmOwnerChange(state),

    changeDistributionTariff: isCrossSell(state) && isElectricity(state),
    commodity: getCommodity(state),

    /* Enumerations */
    titlePrefixes: getEnumeration<TitlePrefix>(state, ENUMERATION_TITLE_PREFIX),
    titleSuffixes: getEnumeration<TitleSuffix>(state, ENUMERATION_TITLE_SUFFIX),
    positions: getEnumeration<Position>(state, ENUMERATION_POSITIONS),
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface ContactFormProps {
  signed: boolean;
  previousPage: () => void;
}

function ContactForm(props: ContactFormProps & PropsFromRedux & InjectedFormProps) {
  const {
    handleSubmit,
    signed,
    household,
    transfer,
    previousPage,
    titlePrefixes,
    titleSuffixes,
    positions,
    newOmOwnerChange,
    commodity,
    submitting,
  } = props;

  let field;

  if (household) {
    field = (
      <Field
        name="contact"
        component={Household}
        props={{
          titlePrefixes,
          titleSuffixes,
          signed,
          transfer,
          newOmOwnerChange,
          commodity,
        }}
      />
    );
  } else {
    field = (
      <Field
        name="contact"
        component={Company}
        props={{
          titlePrefixes,
          titleSuffixes,
          positions,
          signed,
          transfer,
          newOmOwnerChange,
        }}
      />
    );
  }

  return (
    <Container>
      <div>
        <ProgressBar page={Pages.Contact} />
        <Column>
          <Title name={locs("titles.contact_information")} underline />
        </Column>
        {field}
        <div className={styles.footer}>
          <Link label={locs("actions.go_back")} onClick={previousPage} />
          <Button disabled={submitting} onClick={handleSubmit}>
            {submitting ? <InlineLoader height={12} /> : locs("actions.next")}
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default compose(
  reduxForm({
    form: formKey, // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    // Observe person name in "person.contact" and "company.contact" and join for "fullName"
    onChange: fullNameOnChange,
    // Check if name and surname are same
    validate: validateSameNames,
  }),
  connector
)(ContactForm);
