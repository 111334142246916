import React from "react";

import locs from "../../localization";
import styles from "./styles.module.scss";
import Address from "../../composites/Address";
import Checkbox from "../../components/Checkbox";
import { WrappedFieldProps, Field } from "redux-form";

interface AddressesProps {
  title?: string;
  deliveryAddressIsSameLabel?: string;
  disabled: boolean;
}

export default function Addresses(props: AddressesProps & WrappedFieldProps) {
  const {
    deliveryAddressIsSameLabel,
    disabled = false,
    title = locs("titles.permanent_address"),
    input: { value, name },
  } = props;
  const { deliveryAddressIsSame } = value || {};

  return (
    <div className={styles.addresses}>
      <Field
        name={`${name}.permanentAddress`}
        component={Address}
        title={title}
        props={{
          disabled,
        }}
      />
      <div className={styles.same_address_container}>
        <Field name={`${name}.deliveryAddressIsSame`} type="checkbox" component={Checkbox} label={deliveryAddressIsSameLabel} />
      </div>
      {!deliveryAddressIsSame && (
        <Field
          name={`${name}.deliveryAddress`}
          component={Address}
          title={locs("titles.delivery_address")}
          props={{
            disabled,
          }}
          className={styles.delivery_address}
        />
      )}
    </div>
  );
}
