import React, { PureComponent, useEffect, useState } from "react";
import classNames from "classnames";
import locs from "../../../localization";

import TooltipContainer from "../../../containers/TooltipContainer";
import Button from "../../../components/Button";
import PriceDetail from "./PriceDetail";

import { PERIOD_MONTH, PERIOD_YEAR } from "../../../constants";
import { priceFormatter, seleniumClass } from "../../../helpers";
import { productPriceConversion } from "../../../helpers/price";
import styles from "./styles.module.scss";
import selectStyles from "../../../components/Select/styles.module.scss";
import { Product } from "../../../reducers/types";
import useIsMobile from "../../../helpers/useIsMobile";

export interface ProductRowProps {
  title: string;
  top: boolean;
  variants: Product[];
  pricePeriod?: typeof PERIOD_MONTH | typeof PERIOD_YEAR;
  onSelect: (value: Product) => void;
}

export const ProductRow: React.FC<ProductRowProps> = ({ title, top, variants, pricePeriod, onSelect }) => {
  const [selected, setSelected] = useState(variants[0]);
  useEffect(() => {
    setSelected(variants[0]);
  }, [variants]);

  const isMobile = useIsMobile();

  const variantTitle = (variant: Product) => locs(`contractDuration.${variant.prices.contractDuration}`);

  const handleSelectVariant = ev => {
    const { value } = ev.target;
    const selectedVariant = variants.find(product => product.value === value);
    if (selectedVariant) {
      setSelected(selectedVariant);
    }
  };

  const handleSelect = () => {
    onSelect(selected);
  };

  const renderSelectVariant = () => {
    return (
      <div className={classNames(selectStyles.select, styles.duration)}>
        <select onChange={handleSelectVariant}>
          {variants.map(product => (
            <option key={product.value} value={product.value}>
              {variantTitle(product)}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const prices = productPriceConversion(selected.prices, PERIOD_YEAR, pricePeriod);

  const price = priceFormatter(prices.total);
  const priceSuffix = pricePeriod === PERIOD_YEAR ? locs("labels.czk_per_year") : locs("labels.czk_per_month");

  const tooltip = <PriceDetail className={styles.tooltip} prices={prices} />;

  return (
    <div className={classNames(styles.product_container, seleniumClass("product"))}>
      <div className={styles.title_container}>
        <h2 className={styles.title}>{title}</h2>
        {top && <div className={styles.tip}>{locs("labels.our_suggestion")}</div>}
        {variants.length > 1 && renderSelectVariant()}
      </div>
      <div className={styles.body}>
        <div className={styles.description_container}>
          <div className={styles.description_inner}>
            <div dangerouslySetInnerHTML={{ __html: selected.description }} />
          </div>
        </div>
        <div className={styles.priceContainer}>
          <div className={styles.price_inner}>
            <h3 className={styles.price}>{`${price} ${priceSuffix}`}</h3>
            <span className={styles.price_with_vat}>{locs("labels.price_with_vat")}</span>
            <Button className={classNames(styles.select_product, seleniumClass("select_product"))} onClick={handleSelect}>
              {locs("labels.select")}
            </Button>
            <TooltipContainer
              trigger={["hover", "click"]}
              overlay={tooltip}
              placement={isMobile ? "top" : "rightTop"}
              align={isMobile ? { offset: [0, -5] } : { offset: [4, -30] }}>
              <span className={styles.moreAboutPrice}>{locs("actions.more_about_the_price")}</span>
            </TooltipContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
