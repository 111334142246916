import React, { PureComponent } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import Link from "../../../components/Link";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import InlineLoader from "../../../components/Loader/InlineLoader";

import locs from "../../../localization";
import formValidation, { NO_MESSAGE } from "../../../validations/formValidation";
import isEmail from "../../../validations/isEmail";
import isLength from "../../../validations/isLength";
import { getError } from "../../../selectors/user";

import styles from "./styles.module.scss";
import theme from "./theme.module.scss";

import { PPAS_FORGOTTEN_PASSWORD, PPAS_REGISTRATION } from "../../../constants";

class SignInView extends PureComponent<InjectedFormProps> {
  handleKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.props.handleSubmit(e);
    }
  };

  handleNotRegistered = () => {
    window.open(PPAS_REGISTRATION, "_blank");
  };

  handleForgottenPassword = () => {
    window.open(PPAS_FORGOTTEN_PASSWORD, "_blank");
  };

  render() {
    const { submitting, error, handleSubmit } = this.props;

    return (
      <div className={styles.signin_container}>
        <div className={styles.logo} />
        <div className={styles.signin_content}>
          <Field
            name="email"
            component={Input}
            validate={[formValidation(isLength({ max: 241 }), NO_MESSAGE), formValidation(isEmail, NO_MESSAGE)]}
            type="text"
            props={{
              prefix: " ",
              theme: { prefix: theme.iconEmail },
              placeholder: locs("placeholders.email"),
            }}
          />
          <Field
            name="password"
            component={Input}
            validate={[formValidation(isLength({ max: 241 }), NO_MESSAGE)]}
            type="password"
            props={{
              prefix: " ",
              theme: { prefix: theme.iconPassword },
              placeholder: locs("placeholders.password"),
            }}
          />
          {error && <p className={styles.error}>{locs("errors.loginError")}</p>}
          <Button disabled={submitting} onClick={handleSubmit}>
            {submitting ? <InlineLoader height={12} /> : locs("actions.signin")}
          </Button>
          <Link label={locs("actions.forgotten_password")} onClick={this.handleForgottenPassword} />
          <div className={styles.divider} />
          <div className={styles.not_registered_container}>
            <span>{locs("labels.not_registered")}</span>
            <Link label={locs("actions.click_here")} onClick={this.handleNotRegistered} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: getError(state),
});

export default compose(
  reduxForm({
    form: "signIn",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  connect(mapStateToProps)
)(SignInView);
