import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import theme from "./theme.module.scss";

import Icon, { CHECK } from "../UI/Icon";

interface ItemProps extends React.HTMLProps<HTMLDivElement> {
  selected?: boolean;
  completed?: boolean;
  order: string;
}

const item = ({ selected = false, completed = false, children, order }: ItemProps) => {
  const numberClassNames = classNames(styles.number, {
    [styles.numberSelected]: selected,
  });

  const labelClassNames = classNames(styles.label, {
    [styles.labelSelected]: selected,
  });

  return (
    <div className={styles.item_container}>
      <div className={numberClassNames}>{completed ? <Icon icon={CHECK} theme={theme} /> : order}</div>
      <div className={labelClassNames}>{children}</div>
    </div>
  );
};

export default item;
