import React from "react";
import Column from "../../containers/Column";
import locs from "../../localization";
import Group from "../../components/Group";
import theme from "./theme.module.scss";

import { SEND_INVOICES_EMAIL, SEND_INVOICES_POST } from "../../constants";
import { WrappedFieldProps } from "redux-form";

interface SendInvoicesProps {
  disabled?: boolean;
}

function SendInvoices({ disabled, input: { name } }: SendInvoicesProps & WrappedFieldProps) {
  return (
    <div>
      <Column>
        <Group
          name={`${name}.sendInvoiceType`}
          label=" "
          theme={theme}
          data={[
            { label: locs("labels.toEmail"), value: SEND_INVOICES_EMAIL, disabled },
            { label: locs("labels.toDeliveryAddress"), value: SEND_INVOICES_POST, disabled },
          ]}
        />
      </Column>
    </div>
  );
}

export default SendInvoices;
