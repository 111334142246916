import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

export interface FormGroupProps {
  className?: string;
  theme?: {
    form_group?: string;
  };
  children?: React.ReactNode;
}

export default function FormGroup({ className, theme = {}, children }: FormGroupProps) {
  return <div className={classNames(className, styles.form_group, theme.form_group)}>{children}</div>;
}
