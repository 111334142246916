import React from "react";
import classNames from "classnames";

import IconClose from "../../../img/ic-close.svg";
import IconDelete from "../../../img/ic-delete.svg";
import IconDeleteWhite from "../../../img/ic-delete-white.svg";
import IconHelp from "../../../img/ic-help.svg";
import IconMail from "../../../img/ic-mail.svg";
import IconPhone from "../../../img/ic-phone.svg";
import IconUser from "../../../img/ic-user.svg";
import IconCheck from "../../../img/ic-check-green.svg";

import styles from "./styles.module.scss";

export const CLOSE = "close";
export const DELETE = "delete";
export const DELETE_WHITE = "delete-white";
export const HELP = "help";
export const MAIL = "mail";
export const PHONE = "phone";
export const USER = "user";
export const CHECK = "check";

const TYPES = [CLOSE, DELETE, DELETE_WHITE, HELP, MAIL, PHONE, USER, CHECK] as const;

export interface IconProps {
  icon: (typeof TYPES)[number];
  theme?: any;
}

export default function Icon({ icon, theme = {} }: IconProps) {
  const className = classNames(styles.icon, theme.icon);

  if (icon === CLOSE) return <IconClose className={className} />;
  if (icon === DELETE) return <IconDelete className={className} />;
  if (icon === DELETE_WHITE) return <IconDeleteWhite className={className} />;
  if (icon === HELP) return <IconHelp className={className} />;
  if (icon === MAIL) return <IconMail className={className} />;
  if (icon === PHONE) return <IconPhone className={className} />;
  if (icon === USER) return <IconUser className={className} />;
  if (icon === CHECK) return <IconCheck className={className} />;

  // Default icon
  throw new Error(`Unknown icon ${icon}`);
}
