import React from "react";

import Done from "../../../img/ic-check-green.svg";
import Error from "../../../img/ic-close.svg";
import Help from "../../../img/ic-help.svg";
import InlineLoader from "../Loader/InlineLoader";
import locs from "../../localization";

import styles from "./styles.module.scss";

export type AresLoadingState = null | "loading" | "loaded" | "error";

export interface AresLoadStatusProps {
  state: AresLoadingState;
}

export default function AresLoadStatus({ state }: AresLoadStatusProps) {
  switch (state) {
    case "loading":
      return (
        <div className={styles.loadingLine}>
          <InlineLoader height={10} subTitle={locs("ares.loading")} />
        </div>
      );
    case "loaded":
      return (
        <div className={styles.svgLine}>
          <Done />
          {locs("ares.loaded")}
        </div>
      );
    case "error":
      return (
        <div className={styles.svgLine}>
          <Error className={styles.red} />
          {locs("ares.error")}
        </div>
      );
    default:
      return (
        <div className={styles.svgLine}>
          <Help />
          {locs("ares.prompt")}
        </div>
      );
  }
}
