import React from "react";
import styles from "./styles.module.scss";

interface RowProps {
  term: string;
  value?: string;
  bold?: boolean;
}

function row({ bold = false, term, value }: RowProps): React.ReactElement {
  return (
    <>
      <dt key={1}>{term}</dt>
      <dd key={2} className={bold ? styles.rowBold : null}>
        {value || "-"}
      </dd>
    </>
  );
}

export default row;
