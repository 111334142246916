import classnames from "classnames";
import React, { PureComponent } from "react";

import styles from "./styles.module.scss";

interface ColumnProps extends React.HTMLProps<HTMLDivElement> {
  columns: number;
  weights: (string | number)[];
  withTooltip?: boolean;
}

export default class Column extends PureComponent<ColumnProps> {
  static defaultProps = {
    columns: null,
    weights: null,
    children: null,
    withTooltip: false,
  };

  render() {
    let { children } = this.props;
    const { columns, weights, withTooltip } = this.props;
    const blankColumns = [];

    if (!Array.isArray(children)) {
      children = [children];
    }

    // @ts-ignore
    if (children.length && columns) {
      // @ts-ignore
      for (let a = 0; a < columns - children.length; a += 1) {
        blankColumns.push(<div key={a} className={styles.blank_node} />);
      }
    }

    return (
      <div className={classnames(styles.columns, { [styles.withTooltip]: withTooltip })}>
        {weights
          ? React.Children.map(children, (child, i) => {
              const className = styles[`flex${weights[i]}`];
              return <div className={className}>{child}</div>;
            })
          : children}
        {blankColumns}
      </div>
    );
  }
}
