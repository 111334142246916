import Moment from "moment";

export default function inFutureDefine(days = 0) {
  function inFuture(dateString: string) {
    if (!dateString) return true;

    const date = Moment(dateString, "D.M.YYYY");
    return date.isAfter(Moment().add(days, "days"));
  }

  inFuture.days = days;

  return inFuture;
}
