import React, { useCallback } from "react";

import Column from "../../containers/Column";
import locs from "../../localization";
import Input from "../../components/Input";
import Title from "../../components/Title";
import Addresses from "./Addresses";
import Person from "./Person";

import formValidation from "../../validations/formValidation";
import isNotEmpty from "../../validations/isNotEmpty";
import isICO from "../../validations/isICO";
import isDIC from "../../validations/isDIC";
import isLength from "../../validations/isLength";

import styles from "./styles.module.scss";
import theme from "./theme.module.scss";
import { Position, TitlePrefix, TitleSuffix } from "../../constants/interfaces";
import { Field, WrappedFieldProps } from "redux-form";
import PowerOfAttorneyUpload from "../../components/PowerOfAttorneyUpload";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { applyAresData, cleanAresData } from "../../actions/ares";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../store";

import AresLoadStatus, { AresLoadingState } from "../../components/AresLoadStatus";

interface CompanyProps {
  titlePrefixes: TitlePrefix[];
  titleSuffixes: TitleSuffix[];
  positions: Position[];
  signed: boolean;
  transfer: boolean;
  newOmOwnerChange: boolean;
}

function Company({ titlePrefixes, positions, titleSuffixes, input: { name } }: CompanyProps & WrappedFieldProps) {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();

  const [aresLoading, setAresLoading] = React.useState<AresLoadingState>(null);

  const changeHandler = useCallback(
    debounce(async newValue => {
      if (isNotEmpty(newValue) && isICO(newValue)) {
        setAresLoading("loading");
        dispatch(applyAresData(newValue, `${name}.addresses.permanentAddress`))
          .then(() => {
            setAresLoading("loaded");
          })
          .catch(() => {
            setAresLoading("error");
            dispatch(cleanAresData(`${name}.addresses.permanentAddress`));
          });
      } else {
        setAresLoading(null);
        dispatch(cleanAresData(`${name}.addresses.permanentAddress`));
      }
    }, 500),
    [dispatch]
  );

  return (
    <div className={styles.contact_container}>
      <Column columns={2} weights={["1_11", 1]}>
        <Field
          name={`${name}.in`}
          id="companyIn"
          autoComplete="off"
          component={Input}
          onChange={(event: { target: { value: string } }) => changeHandler(event.target.value)}
          props={{
            theme,
            label: locs("labels.companyIn"),
          }}
          validate={[formValidation(isNotEmpty), formValidation(isICO)]}
        />
        <AresLoadStatus state={aresLoading} />
      </Column>
      <Column columns={2} weights={["1_11", 1]}>
        <Field
          name={`${name}.tin`}
          id="companyTin"
          autoComplete="off"
          component={Input}
          props={{
            theme,
            label: locs("labels.companyTin"),
          }}
          validate={[formValidation(isLength({ max: 60 })), formValidation(isDIC)]}
        />
      </Column>
      <Column columns={2} weights={["1_11", 1]}>
        <Field
          name={`${name}.name`}
          id="companyName"
          autoComplete="organization"
          component={Input}
          props={{
            theme,
            label: locs("labels.companyName"),
          }}
          validate={[formValidation(isNotEmpty), formValidation(isLength({ max: 255 }))]}
        />
      </Column>
      <div className={styles.competent_person_container}>
        <Title name={locs("titles.competent_person")} tag="h3" className={styles.competent_person_container_title} />
        <Field
          name={`${name}.person`}
          props={{
            disabled: false, // Do NOT disable this, let the user change it
            titlePrefixes,
            titleSuffixes,
            positions,
            showPosition: true,
          }}
          component={Person}
        />
      </div>
      <PowerOfAttorneyUpload
        name={name}
        variable={"powerOfAttorneyCompany"}
        required={false}
        showTemplate={false}
        title={locs("titles.powerOfAttorneyCompany")}
      />
      <Field
        name={`${name}.addresses`}
        component={Addresses}
        props={{
          title: locs("titles.company_address"),
          deliveryAddressIsSameLabel: locs("labels.company_delivery_address_is_same"),
        }}
      />
    </div>
  );
}

export default Company;
