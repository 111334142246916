import Moment from "moment";
import isDate from "./isDate";

export default function dateIsInPast(value) {
  if (!value) return true;
  if (!isDate(value)) return false;

  const date = Moment.isMoment(value) ? value : Moment(value, "D.M.YYYY", true);
  return date.isSameOrBefore(Moment.now());
}
