import sortBy from "lodash/sortBy";
import Moment from "moment";
import { COMMODITY_ELECTRICITY, COMMODITY_GAS, PARTNER_COMPANY, PARTNER_HOUSEHOLD } from "../constants";
import { BaseEnum, Commodity, Partner, Tariff } from "../constants/interfaces";
import { NO_MESSAGE } from "../validations/formValidation";

/**
 *
 * @param {Object} commodity
 * @param {string} commodity.value
 * @return {boolean}
 */
export function isElectricity(commodity: Commodity) {
  return commodity.value === COMMODITY_ELECTRICITY;
}

/**
 *
 * @param {Object} commodity
 * @param {string} commodity.value
 * @return {boolean}
 */
export function isGas(commodity: Commodity) {
  return commodity.value === COMMODITY_GAS;
}

/**
 *
 * @param {Object} partner
 * @param {string} partner.value
 * @return {boolean}
 */
export function isHousehold(partner: Partner) {
  return partner.value === PARTNER_HOUSEHOLD;
}

export function isCompany(partner: Partner) {
  return partner.value === PARTNER_COMPANY;
}

export { priceFormatter } from "./price";

export function seleniumClass(...args) {
  if (import.meta.env.NODE_ENV === "production") return null;

  return args
    .map(arg => {
      // Selenium selector prefix
      const className = ["ss"];

      if (typeof arg === "string") {
        className.push(arg);
      } else if (typeof arg === "object" && arg.name) {
        // Path by input name
        className.push(...(arg.name || "").split("."));
        // It is radio button OR checkbox
        if (Object.prototype.hasOwnProperty.call(arg, "checked")) {
          // Checkbox has boolean value
          if (arg.value !== "" && typeof arg.value !== "boolean") {
            // Suffix for radio button
            className.push(arg.value);
          }
        }
      }

      return className.join("_");
    })
    .join(" ");
}

export function formatEnumerationData<K extends BaseEnum>(items: K[], labelColumn = "value") {
  const result = items.map(t => ({ label: t[labelColumn] || "", value: t.id }));
  if (String.prototype.localeCompare) {
    return result.sort((a, b) => a.label.localeCompare(b.label));
  }
  return sortBy(result, "label");
}

export function enumerationValue<K extends BaseEnum>(items: K[], id: number, labelColumn = "value"): string {
  if (!id) {
    return undefined;
  }

  const value = items.find(x => x.id === id);
  return value ? value[labelColumn] : undefined;
}

export function showErrorMessage(error: string) {
  return error !== NO_MESSAGE;
}

export function formatSizeAndUnits(size: number) {
  const units = {
    byte: "B",
    kilobyte: "KB",
    megabyte: "MB",
    gigabyte: "GB",
    terabyte: "TB",
  };

  let formattedSize;
  let formattedUnits;

  if (size < 1e3) {
    formattedSize = size;
    formattedUnits = units.byte;
  } else if (size >= 1e3 && size < 1e6) {
    formattedSize = (size / 1e3).toFixed(2);
    formattedUnits = units.kilobyte;
  } else if (size >= 1e6 && size < 1e9) {
    formattedSize = (size / 1e6).toFixed(2);
    formattedUnits = units.megabyte;
  } else if (size >= 1e9 && size < 1e12) {
    formattedSize = (size / 1e9).toFixed(2);
    formattedUnits = units.gigabyte;
  } else {
    formattedSize = (size / 1e12).toFixed(2);
    formattedUnits = units.terabyte;
  }

  // trim ending zeros
  formattedSize = formattedSize.replace(/\.?0+$/, "");
  // replace decimal delimiter
  formattedSize = formattedSize.replace(".", ",");

  return `${formattedSize} ${formattedUnits}`;
}
