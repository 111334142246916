import { reducer as reduxFormReducer } from "redux-form";
import omit from "lodash/omit";
import merge from "../helpers/merge";
import { OPEN_LEAD_DIALOG, USER_GET_DATA_SUCCESS, USER_LOGIN_SUCCESS, USER_LOGOUT_SUCCESS } from "../constants/actions";
import { transformUserDataToContract } from "./user";

const formReducer = reduxFormReducer.plugin({
  calculator: (state, action) => {
    switch (action.type) {
      case USER_LOGIN_SUCCESS:
        return merge(state, {
          values: {
            contact: { person: { email: action.login } },
          },
        });
      case USER_GET_DATA_SUCCESS:
        if (state && state.values) {
          return merge(state, {
            values: transformUserDataToContract(action.payload),
          });
        }
        return state;
      case USER_LOGOUT_SUCCESS:
        return {
          ...state,
          // set partnerId as undefined
          values: omit(state.values, ["partnerId"]),
        };
      case OPEN_LEAD_DIALOG:
        return merge(state, {
          values: {
            contact: {
              person: {
                lead_source: action.source,
              },
            },
          },
        });
      default:
        return state;
    }
  },
});

export default formReducer;
