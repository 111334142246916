import React from "react";
import { UPLOAD_ATTACHMENTS_PATH } from "../../api";
import Checkbox from "../../components/Checkbox";
import locs from "../../localization";
import File from "../../components/File";

import styles from "../SupplyPoint/styles.module.scss";
import buttonStyles from "../Desktop/styles.module.scss";
import Title from "../../components/Title";
import { Field, WrappedFieldProps } from "redux-form";
import formValidation from "../../validations/formValidation";
import isNotEmpty from "../../validations/isNotEmpty";
import Button from "../../components/Button";
import { Commodity } from "../../constants/interfaces";
import { isGas } from "../../helpers";

interface OnBehalfProps {
  transfer: boolean;
  signed: boolean;
  commodity: Commodity;
}

export default function OnBehalf(props: OnBehalfProps & WrappedFieldProps) {
  const {
    transfer,
    signed,
    commodity,
    input: { name },
  } = props;

  if (!transfer || signed) {
    return null;
  }

  return (
    <>
      <div>
        <Field name={`${name}.onBehalf`} component={Checkbox} label={locs("labels.transferOnBehalf")} type="checkbox" />
      </div>
      {props.input.value?.onBehalf && (
        <div className={buttonStyles.addressCheckbox}>
          <Title name={locs("titles.uploadPowerOfAttorney")} tag="h3" className={styles.title} />
          <Button
            key="downloadPowerOfAttorney"
            className={buttonStyles.secondaryButton}
            onClick={() => window.open(`pdf/${isGas(commodity) ? "gas" : "power"}_poa.pdf`)}>
            {locs("labels.downloadTemplatePowerOfAttorney")}
          </Button>
          <Field
            name={`${name}.powerOfAttorney`}
            fixedName={true}
            type="file"
            validate={[formValidation(isNotEmpty, locs("errors.fileIsRequired"))]}
            props={{
              endPoints: { request: UPLOAD_ATTACHMENTS_PATH },
              dragFileLabel: locs("actions.drag_file"),
              showDropzone: true,
            }}
            component={File}
          />
        </div>
      )}
    </>
  );
}
