import React from "react";
import format from "string-template";

import Title from "../../components/Title";
import Button from "../../components/Button";
import Link from "../../components/Link";

import locs from "../../localization";
import styles from "./styles.module.scss";
import { priceFormatter } from "../../helpers";
import { Commodity } from "../../constants/interfaces";
import { Product } from "../../reducers/types";
import { PPAS_WEB_URL } from "../../constants";

interface FinalPageProps {
  commodity: Commodity;
  product: Product;
  transfer: boolean;
  toTheFirstPage: (e: unknown) => void;
  bonus: number;
}

const bonusBox = (text: string, buttonText: string, toTheFirstPage: (e: any) => void) => {
  return (
    <div className={styles.bonusBox}>
      <Title name={locs("titles.bonusBox")} className={styles.title} tag="h2" />
      <div className={styles.innerBonusBox}>
        <p className={styles.finalText} dangerouslySetInnerHTML={{ __html: text }} />
        <Button className={styles.newContractButton} onClick={toTheFirstPage}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

const FinalPage: React.FC<FinalPageProps> = props => {
  const { product, toTheFirstPage, transfer, bonus } = props;

  const handleRedirect = () => {
    window.location.href = PPAS_WEB_URL;
  };

  const formattedBonus = bonus && bonus > 0 ? priceFormatter(bonus) : null;
  const finalText = formattedBonus ? format(locs("texts.bonusBoxText"), { bonus: formattedBonus }) : locs("texts.noBonusBoxText");
  const buttonText = formattedBonus ? format(locs("actions.getBonus"), { bonus: formattedBonus }) : locs("actions.newContract");

  return (
    <div className={styles.container}>
      <Title
        name={format(locs("titles.thankYou"), { product: product?.title || "" })}
        className={styles.thankYouTitle}
        tag="h1"
        big
      />
      <p className={styles.finalText}>{locs("texts.thankYouNote")}</p>
      {!transfer && bonusBox(finalText, buttonText, toTheFirstPage)}
      <Link label={locs("actions.backToBeginning")} onClick={handleRedirect} />
    </div>
  );
};

export default FinalPage;
