import React, { PureComponent } from "react";
import range from "lodash/range";
import format from "string-template";
import classNames from "classnames";

import FineUploaderTraditional from "fine-uploader-wrappers";
import FileInput from "react-fine-uploader/file-input";
import Dropzone from "react-fine-uploader/dropzone";
import styles from "./styles.module.scss";
import locs from "../../localization";
import { formatSizeAndUnits, showErrorMessage } from "../../helpers";
import FileDetail, { FileDetailTheme } from "./FileDetail";
import fileUploadOptions from "./fileUploadOptions";
import layout from "../UI/styles.module.scss";
import { WrappedFieldProps } from "redux-form";
import { MEGABYTE } from "../../constants";

interface FileState {
  submittedFiles: any[];
  fileUploadData: any;
}

interface FileProps {
  dropzoneElementId?: string;
  accept?: string[];
  endPoints?: { request: string };
  customHeaders?: any;
  sizeLimit?: number;
  theme?: {
    filesContainer?: string;
    dropzoneWrapper?: string;
    dropzoneElement?: string;
    dropzoneOverlay?: string;
    dropzoneHighlight?: string;
    dropzone?: string;
    orLabel?: string;
    dragLabel?: string;
    dropzoneContainer?: string;
    fileInput?: string;
    allowedFilesLabel?: string;
  } & FileDetailTheme;
  showDropzone?: boolean;
  dragFileLabel?: string;
}

export default class File extends PureComponent<FileProps & WrappedFieldProps & typeof File.defaultProps, FileState> {
  private uploader: any;

  static defaultProps = {
    dropzoneElementId: "ppas",
    accept: ["image/jpeg", "image/png", "application/pdf", "capture=camera"],
    sizeLimit: 3 * MEGABYTE,
    customHeaders: {},
    endPoints: {},
    theme: {},
    showDropzone: true,
  };

  constructor(props) {
    super(props);
    const {
      input: { value },
    } = this.props;

    this.state = {
      submittedFiles: value ? range(0, value.length) : [],
      fileUploadData: {},
    };

    const { endPoints, customHeaders } = props;

    this.uploader = new FineUploaderTraditional(fileUploadOptions(endPoints, customHeaders));
    this.uploader.on("progress", this.progressHandler);
    this.uploader.on("error", this.errorHandler);
    this.uploader.on("manualRetry", this.manualRetryHandler);
    this.uploader.on("submit", this.submitHandler);
    this.uploader.on("onComplete", this.onCompleteHandler);
    this.uploader.methods.addInitialFiles(props.input.value);
  }

  componentWillUnmount() {
    this.uploader.off("progress", this.progressHandler);
    this.uploader.off("error", this.errorHandler);
    this.uploader.off("manualRetry", this.manualRetryHandler);
    this.uploader.off("submit", this.submitHandler);
    this.uploader.off("onComplete", this.onCompleteHandler);
  }

  onCompleteHandler = (id, _name, response) => {
    // Set new file id from BE
    if (response.id && id !== response.id) {
      const file = this.uploader.methods.getFile(id);
      this.uploader.methods.setUuid(id, response.id);

      // Trigger redux-form change - save file
      const {
        input: { value = [], onChange },
      } = this.props;
      const { name, size, type } = file;
      onChange([
        ...value,
        {
          id,
          name,
          size,
          type,
          uuid: response.id,
        },
      ]);
    }
  };

  progressHandler = (id, name, uploadedBytes, totalBytes) => {
    this.updateFileUploadData(id, { uploaded: uploadedBytes, total: totalBytes });
  };

  errorHandler = id => {
    this.updateFileUploadData(id, { error: locs("errors.uploadError") });
  };

  manualRetryHandler = id => {
    this.updateFileUploadData(id, { error: null });
  };

  submitHandler = id => {
    const { submittedFiles } = this.state;
    const file = this.uploader.methods.getFile(id);
    const newState: Partial<FileState> = { submittedFiles: [...submittedFiles, id] };

    let { message, valid } = this.validateFileType(file);
    if (valid) {
      ({ message, valid } = this.validateFileSize(file));
    }

    if (message) {
      const { fileUploadData } = this.state;
      const newFileUploadData = { ...fileUploadData };
      newFileUploadData[id] = { ...fileUploadData[id], error: message };
      newState.fileUploadData = newFileUploadData;
    }

    this.setState(newState as any);

    return valid;
  };

  updateFileUploadData = (id, newData) => {
    const { fileUploadData } = this.state;
    const newFileUploadData = { ...fileUploadData };
    newFileUploadData[id] = { ...fileUploadData[id], ...newData };
    this.setState({ fileUploadData: newFileUploadData });
  };

  removeFileHandler = i => {
    this.uploader.methods.deleteFile(i);
    const { submittedFiles } = this.state;
    const newSubmittedFiles = submittedFiles.filter(fileId => fileId !== i);
    this.setState({ submittedFiles: newSubmittedFiles });

    // Trigger redux-form change - remove file
    const {
      input: { value = [], onChange },
    } = this.props;
    const files = value.filter(f => f.id !== i);
    onChange(files);
  };

  validateFileType = file => {
    const { type: mimeType } = file || {};
    const valid = !!this.props.accept.includes(mimeType);
    const message = locs("errors.unsupportedFileType");
    return { valid, message: valid ? null : message };
  };

  validateFileSize = file => {
    const { sizeLimit } = this.props;
    const valid = file.size < sizeLimit;
    const message = format(locs("errors.fileIsTooLarge"), { size: formatSizeAndUnits(sizeLimit) });
    return { valid, message: valid ? null : message };
  };

  render() {
    const { accept, dropzoneElementId, showDropzone, theme, meta, sizeLimit } = this.props;
    const { submittedFiles, fileUploadData } = this.state;
    const { uploader } = this;

    const isInvalid = () => meta?.invalid && meta?.touched;
    const error = isInvalid() && showErrorMessage(meta.error) && <div className={styles.errorMessage}>{meta.error}</div>;

    return (
      <div>
        <div className={classNames(styles.dropzoneOverlay, theme.dropzoneOverlay)} />

        <div className={classNames(styles.filesContainer, theme.filesContainer)}>
          {submittedFiles.map(id => (
            <FileDetail
              key={id}
              id={id}
              uploader={uploader}
              fileUploadData={fileUploadData[id]}
              removeFile={this.removeFileHandler}
              theme={theme}
            />
          ))}
        </div>

        <div className={classNames(styles.dropzoneWrapper, theme.dropzoneWrapper)}>
          <Dropzone
            uploader={uploader}
            multiple
            element={document.getElementById(dropzoneElementId)}
            dropActiveClassName={classNames(styles.dropzoneHighlight, theme.dropzoneHighlight)}
            className={classNames(layout.form_control, theme.dropzoneElement, {
              [styles.dropzone]: showDropzone,
              [theme.dropzone]: showDropzone,
            })}>
            {showDropzone && (
              <div className={classNames(styles.dropzoneContainer, theme.dropzoneContainer)}>
                <div className={classNames(styles.dragLabel, theme.dragLabel)}>
                  {this.props.dragFileLabel || locs("actions.drag_file")}
                </div>
                <div className={classNames(styles.orLabel, theme.orLabel)}>{locs("labels.or")}</div>
                <FileInput
                  uploader={uploader}
                  multiple
                  className={classNames(styles.fileInput, theme.fileInput)}
                  accept={accept.join(", ")}
                  text={{ selectFiles: locs("actions.select_file_manually") }}
                />
              </div>
            )}
            {!showDropzone && (
              <FileInput
                uploader={uploader}
                multiple
                className={classNames(styles.fileInput, theme.fileInput)}
                accept={accept.join(", ")}
                text={{ selectFiles: locs("actions.select_file") }}
              />
            )}
          </Dropzone>
          <p className={classNames(styles.allowedFilesLabel, theme.allowedFilesLabel, layout.form_control)}>
            {format(locs("texts.allowedFiles"), { size: formatSizeAndUnits(sizeLimit) })}
          </p>
          {error}
        </div>
      </div>
    );
  }
}
