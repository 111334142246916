import React from "react";
import { InjectedFormProps, reduxForm } from "redux-form";
import locs from "../../localization";

import Container from "../../containers/Container";
import Column from "../../containers/Column";
import Title from "../../components/Title";
import Button from "../../components/Button";

import ProgressBar from "../../components/ProgressBar";
import styles from "./styles.module.scss";

import { compose, Dispatch } from "redux";
import Group from "../../components/Group";
import { PARTNER_HOUSEHOLD, TYPE_NEW_OM, TYPE_NEW_OM_OWNER_CHANGE, TYPE_NEW_OM_OWNER_CHANGE_DEATH } from "../../constants";
import { Pages } from "../../lifecycles";
import { CalculatorValues } from "../../reducers/types";
import InlineLoader from "../../components/Loader/InlineLoader";
import Link from "../../components/Link";
import { connect } from "react-redux";
import TooltipContainer from "../../containers/TooltipContainer";
import Help from "../../components/Help";

const formKey = "calculator";

interface ProcessSelectPageProps {
  previousPage: () => void;
}

const tooltipWrapper = (key: string) => {
  const label = locs(`labels.${key}`);
  return <span>{label}</span>;
};

const helpWrapper = (key: string) => {
  const helpText = locs(`texts.${key}_help`);
  return (
    <Help>
      <span style={{ whiteSpace: "pre" }}>{helpText}</span>
    </Help>
  );
};

function ProcessSelectPage({ handleSubmit, submitting, previousPage }: ProcessSelectPageProps & InjectedFormProps) {
  const data = [
    {
      label: tooltipWrapper("processAcquisition"),
      help: helpWrapper("processAcquisition"),
      value: TYPE_NEW_OM,
    },
    {
      label: tooltipWrapper("processTransferCustomer"),
      help: helpWrapper("processTransferCustomer"),
      value: TYPE_NEW_OM_OWNER_CHANGE,
    },
    {
      label: tooltipWrapper("processTransferDeath"),
      help: helpWrapper("processTransferDeath"),
      value: TYPE_NEW_OM_OWNER_CHANGE_DEATH,
    },
  ];

  return (
    <Container>
      <ProgressBar page={Pages.ProcessSelect} />
      <Column>
        <Title name={locs("titles.processSelect")} />
      </Column>
      <Column>
        <Group
          name="process"
          direction="vertical"
          data={data}
          theme={{
            groupContainer: styles.processSelectionGroupContainer,
            helpWrapper: styles.processSelectWrapper,
          }}
        />
      </Column>
      <div className={styles.footer}>
        <Link label={locs("actions.go_back")} onClick={previousPage} />
        <Button disabled={submitting} onClick={handleSubmit}>
          {submitting ? <InlineLoader height={12} /> : locs("actions.next")}
        </Button>
      </div>
    </Container>
  );
}

const handleFormChange = (newValues: CalculatorValues, dispatch: Dispatch<any>, props: InjectedFormProps<any>) => {
  const { change: changeField } = props;

  if (newValues.process === TYPE_NEW_OM_OWNER_CHANGE_DEATH) {
    // Change value of previous customer to "household", "company is not available"
    // @ts-ignore
    dispatch(changeField("transfer.previousCustomer.previousPartnerValue", PARTNER_HOUSEHOLD));
  }
};

export default compose(
  reduxForm({
    form: formKey, // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    onChange: handleFormChange,
  }),
  connect()
)(ProcessSelectPage);
