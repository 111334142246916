import React from "react";

import DataFilledByCustomer from "./DataFilledByCustomer";
import DataFilledWithPpas from "./DataFilledWithPpas";

import { FILL_DATA_MYSELF, FILL_DATA_WITH_PPAS } from "../../constants";

import styles from "./styles.module.scss";
import { Commodity, Supplier, Appliance } from "../../constants/interfaces";
import { Field, WrappedFieldProps } from "redux-form";

export interface SupplyPointProps {
  suppliers: Supplier[];
  appliances: Appliance[];
  commodity: Commodity;
  knownConsumption?: boolean;
  disabled?: boolean;
  isCrossSell?: boolean;
}

export default function SupplyPoint(props: SupplyPointProps & WrappedFieldProps) {
  const {
    commodity,
    suppliers,
    appliances,
    isCrossSell,
    disabled = false,
    knownConsumption = false,
    input: { name, value: { fillDataType } = {} } = {},
  } = props;

  const filledDataByCustomer = fillDataType === FILL_DATA_MYSELF || isCrossSell;

  return (
    <div className={styles.container}>
      {/* There was an option to select "fillDataMyself" or "fillDataWithPpas" */}
      {filledDataByCustomer && (
        <Field
          name={`${name}.data`}
          props={{
            commodity,
            suppliers,
            appliances,
            knownConsumption,
            disabled: disabled,
            isCrossSell,
          }}
          component={DataFilledByCustomer}
        />
      )}
      {!filledDataByCustomer && (
        <Field
          name={`${name}.data`}
          props={{
            commodity,
          }}
          component={DataFilledWithPpas}
        />
      )}
    </div>
  );
}
