import React, { PureComponent } from "react";
import styles from "./styles.module.scss";

import GasAppliance from "./GasAppliance";
import { WrappedFieldArrayProps } from "redux-form";
import { Appliance } from "../../constants/interfaces";
import FixedTooltip from "../../components/FixedTooltip";
import locs from "../../localization";

interface GasApplianceProps {
  appliances: Appliance[];
  disabled?: boolean;
  consumptionRequired?: boolean;
}

export default class GasAppliances extends PureComponent<GasApplianceProps & WrappedFieldArrayProps<any>> {
  constructor(props) {
    super(props);
    const { fields } = props;
    if (!fields.length) {
      fields.push();
    }
  }

  render() {
    const { fields, appliances, disabled, meta, consumptionRequired } = this.props;

    return (
      <div className={styles.gasAppliances}>
        {consumptionRequired && (
          <FixedTooltip position="none" theme={{ tooltipContainer: styles.validToTooltip }}>
            <div>{locs(`texts.gas_appliances_memo`)}</div>
          </FixedTooltip>
        )}
        {fields.map((member, index) => (
          <GasAppliance
            key={member}
            member={member}
            index={index}
            fields={fields}
            disabled={disabled}
            appliances={appliances}
            consumptionRequired={consumptionRequired}
          />
        ))}
        {meta.error && meta.submitFailed && <div className={styles.errorMessage}>{meta.error}</div>}
      </div>
    );
  }
}
