import { useEffect } from "react";
import { connect } from "react-redux";
import { LeadSource, openLeadDialog } from "../../actions/leadDialog";

interface LeaveDetectionProps {
  openLeadDialog: (source: LeadSource) => void;
  shown: boolean;
}

const mapStateToProps = (state: any) => ({
  shown: state.lead.shown,
});

const mapDispatchToProps = {
  openLeadDialog,
};

const LeaveDetection = (props: LeaveDetectionProps) => {
  const { openLeadDialog, shown } = props;

  const handleMouseOut = (e: { clientY: number; clientX: number }) => {
    if (
      (e.clientY < 10 && e.clientX > window.innerWidth * 0.7) || // Windows/Linux users
      (e.clientY < 10 && e.clientX < window.innerWidth * 0.3) // Mac users
    ) {
      openLeadDialog("popup");
    }
  };

  useEffect(() => {
    if (shown) {
      document.removeEventListener("mouseout", handleMouseOut);
    } else {
      document.addEventListener("mouseout", handleMouseOut);
    }

    return () => {
      document.removeEventListener("mouseout", handleMouseOut);
    };
  }, [shown]);

  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveDetection);
