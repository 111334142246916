import React, { PureComponent } from "react";
import compose from "recompose/compose";
import { connect, ConnectedProps } from "react-redux";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import Loader from "../../components/Loader";
import Container from "../../containers/Container";
import Group from "../../components/Group";
import Link from "../../components/Link";
import FormGroup from "../../components/UI/FormGroup";
import Separator from "../../components/Separator";

import { NEW, PERIOD_MONTH, PERIOD_YEAR } from "../../constants";

import styles from "./styles.module.scss";

import Products from "../Calculator/Products";
import Login from "../Calculator/Login";
import User from "../User";

import locs from "../../localization";
import { seleniumClass } from "../../helpers";

import { fetchProducts } from "../../actions/contract";
import { DATA_REQUEST, DATA_SUCCESS } from "../../constants/async";
import {
  getConsumption,
  getEstimate,
  getKnownConsumption,
  getPartnerValue,
  isElectricity,
  isGas,
  isPartnerIdSelected,
  isTransfer,
} from "../../selectors/contract";
import { handleFormChangePayments } from "../Payments/PaymentMethods";
import CallMeWidget from "../../components/CallMeWidget";
import LeaveDetection from "../../components/LeaveDetection";
import { openLeadDialog } from "../../actions/leadDialog";
import { isSettingEnabled } from "../../selectors/setting";
import ConsumptionInfo from "../../components/ConsumptionInfo";

// Decorate with connect to read form values
const formKey = "calculator";

const selector = formValueSelector(formKey);

function mapStateToProps(state, props) {
  const { contractStatus, productsStatus, data } = state.products;
  const { initialValues } = props;

  const isLoaded = productsStatus === DATA_SUCCESS;

  return {
    pricePeriod: selector(state, "pricePeriod") || (initialValues.pricePeriod as string),
    partnerId: selector(state, "partnerId") as string,
    partnerValue: getPartnerValue(state),

    electricity: isElectricity(state),
    gas: isGas(state),
    knownConsumption: getKnownConsumption(state),
    consumption: getConsumption(state),
    estimate: getEstimate(state),
    isTransfer: isTransfer(state),

    isLoadable: contractStatus === DATA_SUCCESS || isPartnerIdSelected(state),
    isLoading: productsStatus === DATA_REQUEST,
    isLoaded,
    products: data,

    productStubEnabled: isSettingEnabled(state, "lead_stub_product"),
    callMeWidgetEnabled: isSettingEnabled(state, "lead_widget"),
    leaveDetectionEnabled: isSettingEnabled(state, "lead_leave_detection"),
  };
}

const mapDispatchToProps = {
  fetchProducts,
  openLeadDialog,
};

interface SelectProductProps {
  /** app callbacks */
  onSubmit: () => void;
  previousPage: () => void;
  signed: boolean;
  persistData: () => void;
  getData: (waitForInit?: boolean) => void;
  toTheSpecificPage: (page: string) => void;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface SelectProductState {
  showLogin: boolean;
}

class SelectProduct extends PureComponent<SelectProductProps & PropsFromRedux & InjectedFormProps, SelectProductState> {
  static defaultProps = {
    products: [],
    signed: false,
    estimate: { consumption: "0" },
  };

  state = {
    showLogin: false,
  };

  componentDidMount() {
    if (this.props.isLoadable && !this.props.isLoading && !this.props.isLoaded) {
      // Fallback for reload Product page
      this.props.fetchProducts();
    }
  }

  handleSelectProduct = product => {
    const { change, onSubmit, signed, partnerId } = this.props;

    // Write selected product to the form
    change("product", product);

    // partnerId must be selected (or marked as new)
    if (signed && partnerId) {
      onSubmit();
    } else {
      this.setState({ showLogin: true });
    }
  };

  handleNewPartner = () => {
    // Product has been selected before ...
    // Mark partner as new
    this.props.change("partnerId", NEW);

    // And continue
    this.props.onSubmit();
  };

  handleLoginClose = () => {
    this.setState({ showLogin: false });
  };

  handleSelectPartner = (partnerId: string, newPartnerValue: number) => {
    const { change, partnerValue, onSubmit } = this.props;

    // Write selected account and partner
    change("partnerId", partnerId);

    if (partnerValue !== newPartnerValue) {
      // Change partner value
      change("partnerValue", newPartnerValue);

      // Drop last selected product ... it is probably invalid
      change("product", null);

      // Close login
      this.handleLoginClose();

      // We have to persist form & reload user data
      // fom partner & continue
      Promise.resolve(null)
        .then(this.props.persistData) // save the form to trigger changes
        .then(() => this.props.getData(false)) // reload changed user
        .then(this.props.fetchProducts); // reload products
    } else {
      onSubmit();
    }
  };

  render() {
    const { isLoaded, electricity, gas, knownConsumption, previousPage, pricePeriod, products, signed, estimate, isTransfer } =
      this.props;

    const { showLogin } = this.state;

    return (
      <div>
        <Loader loaded={isLoaded}>
          <Container>
            <div>
              <h1>{locs("titles.recommended_products")}</h1>
              {signed && <User />}
              <Separator />

              <ConsumptionInfo
                estimate={estimate}
                knownConsumption={knownConsumption}
                spConsumption={this.props.consumption}
                electricity={electricity}
                locs={locs}
              />

              <FormGroup theme={{ form_group: styles.formGroup }}>
                <Group
                  name="pricePeriod"
                  theme={{ groupContainer: styles.pricePeriodGroup }}
                  data={[
                    { label: locs("labels.price_month"), value: PERIOD_MONTH },
                    { label: locs("labels.price_year"), value: PERIOD_YEAR },
                  ]}
                />
              </FormGroup>
              <Products
                products={products}
                pricePeriod={pricePeriod}
                onSelect={this.handleSelectProduct}
                showProductStub={!isTransfer && this.props.productStubEnabled}
              />
              <hr className={styles.separator} />
              <Link className={seleniumClass("previous")} label={locs("actions.back")} onClick={previousPage} />
            </div>
          </Container>
        </Loader>
        {showLogin && (
          <Login
            onClose={this.handleLoginClose}
            onSelectPartner={this.handleSelectPartner}
            onNotCustomer={this.handleNewPartner}
          />
        )}
        {!isTransfer && (
          <div>
            {this.props.callMeWidgetEnabled && <CallMeWidget />}
            {this.props.leaveDetectionEnabled && <LeaveDetection />}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  reduxForm({
    form: formKey, // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    onChange: handleFormChangePayments,
  }),
  connector
)(SelectProduct);
