import React from "react";
import MaskedInput from "react-text-mask";
import classNames from "classnames";
import layout from "../UI/styles.module.scss";
import styles from "./styles.module.scss";
import { seleniumClass, showErrorMessage } from "../../helpers";
import { WrappedFieldProps } from "redux-form";
import DOMPurify from "dompurify";

export interface InputProps {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  suffix?: string;
  prefix?: string | React.ReactNode;
  autoComplete?: string;
  theme?: {
    form_control?: string;
    inputContainer?: string;
    suffixContainer?: string;
    input?: string;
    baseContainer?: string;
    prefix?: string;
    suffix?: string;
    label?: string;
  };
  onKeyDown?: () => void;
  mask?: string[];
  renderErrorAsHtml?: boolean;
}

export default function Input(props: InputProps & WrappedFieldProps) {
  // Filter not number characters
  // TODO Use only for IE/Edge
  const handleNumberKeypress = e => {
    const char = String.fromCharCode(e.which);
    // Allow only numbers and delimiters
    if (/[^0-9,.]/.test(char)) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  const {
    input,
    label,
    placeholder,
    type = "text",
    disabled = false,
    onKeyDown,
    suffix,
    prefix,
    theme = {},
    meta,
    mask = [],
    autoComplete,
    renderErrorAsHtml,
  } = props;

  const isInvalid = () => meta.invalid && meta.touched;
  const inputContainerClassNames = classNames(theme.inputContainer, styles.inputContainer);

  const prefixContainerClassNames = classNames({
    [styles.suffixContainer]: suffix,
    [theme.suffixContainer]: suffix,
    [styles.prefixContainer]: prefix,
  });

  const baseContainerClassNames = classNames(
    { [layout.form_control]: type !== "hidden" },
    { [styles.hidden]: type === "hidden" },
    theme.form_control,
    theme.baseContainer
  );
  const inputClassNames = classNames(theme.input, styles.input, { [styles.inputInvalid]: isInvalid() });
  const suffixClassNames = classNames(theme.suffix, styles.suffix);
  const prefixClassNames = classNames(theme.prefix, styles.prefix);
  const labelClassNames = classNames(theme.label, styles.label);
  const message = renderErrorAsHtml ? (
    <div className={styles.errorMessage} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(meta.error) }} />
  ) : (
    <div className={styles.errorMessage}>{meta.error}</div>
  );
  const error = isInvalid() && showErrorMessage(meta.error) && message;

  let InputType: any = "input";
  if (mask && mask.length) {
    InputType = MaskedInput;
  } else if (type === "textarea") {
    InputType = "textarea";
  }

  return (
    <div className={baseContainerClassNames}>
      <label className={seleniumClass(input)}>
        {label && <div className={labelClassNames}>{label}</div>}
        <div className={inputContainerClassNames}>
          <div className={prefixContainerClassNames}>
            {prefix && <span className={prefixClassNames}>{prefix}</span>}
            <InputType
              {...input}
              placeholder={placeholder}
              disabled={disabled}
              type={type}
              className={inputClassNames}
              onKeyDown={onKeyDown}
              mask={mask}
              autoComplete={autoComplete}
              onKeyPress={type === "number" ? handleNumberKeypress : null}
            />
            {suffix && <span className={suffixClassNames}>{suffix}</span>}
          </div>
          {error}
        </div>
      </label>
    </div>
  );
}
