import React, { Component } from "react";
import classNames from "classnames";
import { seleniumClass, showErrorMessage } from "../../helpers";

import locs from "../../localization";
import layout from "../UI/styles.module.scss";
import styles from "./styles.module.scss";
import inputStyles from "../Input/styles.module.scss";
import { WrappedFieldProps } from "redux-form";

export const BLANK_OPTION_VALUE = "";

export interface SelectItem<T> {
  value: T;
  label?: string;
}

export interface SelectProps<T> {
  label?: string;
  grouped?: boolean;
  disabled?: boolean;
  showBlankOption?: boolean;
  blankOption?: string;
  theme?: {
    select?: string;
    form_control?: string;
    baseContainer?: string;
    label?: string;
    inputContainer?: string;
  };
  data: SelectItem<T>[] | { [key: string]: SelectItem<T>[] };
}

export default class Select<T extends number | string = number | string> extends Component<SelectProps<T> & WrappedFieldProps> {
  static createDataStructure(data) {
    let formattedData = data;
    if (data.constructor !== Array) {
      formattedData = Object.keys(data).map(k => ({ label: data[k], value: k }));
    }
    return formattedData;
  }

  isInvalid = () => this.props.meta.invalid && this.props.meta.touched;

  render() {
    const { input, label, meta, grouped, disabled, showBlankOption, blankOption, theme = {} } = this.props;
    let { data = [] } = this.props;
    if (!grouped) data = Select.createDataStructure(data);

    const selectClassNames = classNames(
      this.isInvalid() ? styles.select_invalid : styles.select,
      theme.select,
      seleniumClass(input)
    );
    const selectClassContainerNames = classNames(layout.form_control, theme.form_control, theme.baseContainer);
    const labelClassNames = classNames(theme.label, styles.label);

    const inputContainerClassNames = classNames(theme.inputContainer, inputStyles.inputContainer);

    const blankOptionEl = showBlankOption ? (
      <option key={0} value={BLANK_OPTION_VALUE}>
        {blankOption || locs("actions.choose")}
      </option>
    ) : null;

    let formattedData;
    if (grouped) {
      formattedData = Object.entries(data).map(group => (
        <optgroup key={group[0]} label={group[0]}>
          {group[1].map(child => (
            <option key={child} value={child}>
              {child}
            </option>
          ))}
        </optgroup>
      ));
    } else {
      formattedData = (data as SelectItem<T>[]).map(child => (
        <option key={child.value} value={child.value}>
          {child.label}
        </option>
      ));
    }

    return (
      <div className={selectClassContainerNames}>
        <label className={selectClassNames}>
          {label && <div className={labelClassNames}>{label}</div>}
          <div className={inputContainerClassNames}>
            <select {...input} disabled={disabled}>
              {blankOptionEl}
              {formattedData}
            </select>
            {this.isInvalid() && showErrorMessage(meta.error) && <div className={styles.errorMessage}>{meta.error}</div>}
          </div>
        </label>
      </div>
    );
  }
}
