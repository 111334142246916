import React from "react";
import format from "string-template";
import locs from "../../localization";

import Title from "../../components/Title";
import InvoiceFile from "./InvoiceFile";

import { isElectricity } from "../../helpers";
import styles from "./styles.module.scss";
import { Commodity } from "../../constants/interfaces";
import { WrappedFieldProps } from "redux-form";

interface DataFilledWithPpasProps {
  commodity: Commodity;
}

export default function DataFilledWithPpas({ commodity, input: { name } }: DataFilledWithPpasProps & WrappedFieldProps) {
  const commodityLabel = isElectricity(commodity) ? locs("labels.electricityAcc") : locs("labels.gasAcc");
  const title = format(locs("titles.uploadYourInvoice"), { commodity: commodityLabel });

  return (
    <div>
      <Title name={title} tag="h3" className={styles.title} />
      <div className={styles.file}>
        <InvoiceFile name={name} isRequired={true} />
      </div>
    </div>
  );
}
