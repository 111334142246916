import React, { PureComponent } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { WrappedFieldProps } from "redux-form";

interface ExpandProps {
  showLabel?: string;
  hideLabel?: string;
  children?: any;
  theme?: any;
  iconType?: "plusMinus" | "arrows";
}

export default class Expand extends PureComponent<ExpandProps & WrappedFieldProps> {
  onChangeHandler = () => {
    const { input } = this.props;
    input.onChange(!input.value);
  };

  render() {
    const { children, hideLabel, showLabel, iconType = "plusMinus", theme = {}, input: { value: visible } = {} } = this.props;

    const buttonClassNames = classNames(styles.button, theme.button, styles[iconType], { [styles[`${iconType}Show`]]: !visible });
    const baseContainerClassNames = classNames(theme.baseContainer);

    return (
      <div className={baseContainerClassNames}>
        <button onClick={this.onChangeHandler} tabIndex={-1} className={buttonClassNames}>
          {visible ? hideLabel : showLabel}
        </button>
        {visible && <div>{children}</div>}
      </div>
    );
  }
}
