import { get, put, post } from "../api";
import toSnakeCase from "../helpers/toSnakeCase";
import toCamelCase from "../helpers/toCamelCase";
import {
  CONTRACT_PRODUCTS_FAILURE,
  CONTRACT_PRODUCTS_REQUEST,
  CONTRACT_PRODUCTS_SUCCESS,
  CONTRACT_RESTORE_FAILURE,
  CONTRACT_RESTORE_REQUEST,
  CONTRACT_RESTORE_SUCCESS,
  CONTRACT_SAVE_FAILURE,
  CONTRACT_SAVE_REQUEST,
  CONTRACT_SAVE_SUCCESS,
  CONTRACT_CONFIRM_FAILURE,
  CONTRACT_CONFIRM_REQUEST,
  CONTRACT_CONFIRM_SUCCESS,
  CONTRACT_CREATE_PDF_REQUEST,
  CONTRACT_CREATE_PDF_SUCCESS,
  CONTRACT_CREATE_PDF_FAILURE,
  CONTRACT_CHECK_NEW_TRANSFER_REQUEST,
  CONTRACT_CHECK_NEW_TRANSFER_SUCCESS,
  CONTRACT_CHECK_NEW_TRANSFER_FAILURE,
} from "../constants/actions";
import { COMMODITY_ELECTRICITY, COMMODITY_GAS } from "../constants";
import { LeadSource } from "./leadDialog";

export function createForm() {
  return () => {
    return post("/contract/form").catch(error => {
      throw error;
    });
  };
}

function saveContract(payload: any, path: string) {
  return dispatch => {
    dispatch({ type: CONTRACT_SAVE_REQUEST, payload });
    return put(path, toSnakeCase(payload, [COMMODITY_ELECTRICITY, COMMODITY_GAS]))
      .then(data => dispatch({ type: CONTRACT_SAVE_SUCCESS, payload: data }))
      .catch(error => {
        dispatch({ type: CONTRACT_SAVE_FAILURE, payload: error, query: payload, status: error.response.status });
        throw error;
      });
  };
}

export function saveForm(payload: any) {
  return saveContract(payload, "/contract/form");
}

export function submitLead(payload: any) {
  const source = payload.contact.person.source as LeadSource;
  return saveContract({ ...payload, submitted: true, source: source }, "/contract/lead");
}

export function fetchForm() {
  return dispatch => {
    dispatch({ type: CONTRACT_RESTORE_REQUEST });
    return get("/contract/form")
      .then(data => {
        const payload = toCamelCase(data, [COMMODITY_ELECTRICITY, COMMODITY_GAS]);
        dispatch({ type: CONTRACT_RESTORE_SUCCESS, payload });
      })
      .catch(error => dispatch({ type: CONTRACT_RESTORE_FAILURE, payload: error }));
  };
}

export function confirmForm() {
  return dispatch => {
    dispatch({ type: CONTRACT_CONFIRM_REQUEST });
    return post("/contract/confirm")
      .then(data => dispatch({ type: CONTRACT_CONFIRM_SUCCESS, payload: data }))
      .catch(error => {
        dispatch({ type: CONTRACT_CONFIRM_FAILURE, payload: error, status: error.response.status });
        throw error;
      });
  };
}

export function createPdf() {
  return dispatch => {
    dispatch({ type: CONTRACT_CREATE_PDF_REQUEST });

    return post("/contract/create_pdf")
      .then(data => dispatch({ type: CONTRACT_CREATE_PDF_SUCCESS, payload: data }))
      .catch(error => {
        dispatch({ type: CONTRACT_CREATE_PDF_FAILURE, payload: error, status: error.response.status });
        throw error;
      });
  };
}

export function checkNewTransfer() {
  return dispatch => {
    dispatch({ type: CONTRACT_CHECK_NEW_TRANSFER_REQUEST });

    return post("/contract/check_new_transfer")
      .then(data => dispatch({ type: CONTRACT_CHECK_NEW_TRANSFER_SUCCESS, payload: data }))
      .catch(error => {
        dispatch({ type: CONTRACT_CHECK_NEW_TRANSFER_FAILURE, payload: error, status: error.response.status });
        throw error;
      });
  };
}

export function fetchProducts() {
  return dispatch => {
    dispatch({ type: CONTRACT_PRODUCTS_REQUEST });

    return get("/contract/products")
      .then(data => data.map(row => toCamelCase(row)))
      .then(data =>
        dispatch({
          type: CONTRACT_PRODUCTS_SUCCESS,
          payload: data,
        })
      )
      .catch(error => {
        dispatch({ type: CONTRACT_PRODUCTS_FAILURE, payload: error, status: error.response.status });
        throw error;
      });
  };
}
