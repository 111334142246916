import { CLOSE_LEAD_DIALOG, LEAD_SUCCESSFULLY_SUBMITTED, OPEN_LEAD_DIALOG } from "../constants/actions";

export type LeadSource = "product_stub" | "widget" | "popup";

export const openLeadDialog = (source: LeadSource) => ({
  type: OPEN_LEAD_DIALOG,
  source: source,
});

export const closeLeadDialog = () => ({
  type: CLOSE_LEAD_DIALOG,
});

export const leadSubmitted = () => ({
  type: LEAD_SUCCESSFULLY_SUBMITTED,
});
