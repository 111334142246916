import { REGIONS, UNKNOWN } from "../../constants";

export default {
  validators: {},
  labels: {
    optional: "(nepovinné)",
    marketingAgreement: "Marketingový souhlas",
    yes: "Ano",
    no: "Ne",
    agree_with_a_contract: "Souhlasím se zněním <b>Smlouvy</b> a <b>Plné moci</b> a",
    signature_location: "V",
    signature_day: "dne",
    delete: "Smazat",
    household: "Domácnost",
    company: "Firma",
    mwh_year: "MWh / rok",
    mwh: "MWh",
    a: "A",
    m3_year: "m³ / rok",
    m3: "m³",
    kw: "kW",
    your_consumption: "Vaše spotřeba",
    gasUsage: "K čemu plyn používáte?",
    electricityUsage: "K čemu elektřinu používáte",
    cooking: "Vařím",
    water_heating: "Ohřívám vodu",
    heating: "Topím",
    heating_little: "Topím málo",
    overheating: "Topím hodně",
    lighting: "Svítím",
    heat_pump: "Mám tepelné čerpadlo",
    electric_car: "Používám elektromobil",
    flat: "V bytě",
    family_house: "V rodinném domě",
    distribution_rate: "Distribuční sazba",
    phases: "Počet fází jističe",
    reserved_powers: "Počet ampér jističe",
    high_tariff: "Vysoký tarif",
    low_tariff: "Nízký tarif",
    high_tariff_shortcut: "VT",
    low_tariff_shortcut: "NT",
    expected_consumption: "Vaše předpokládaná roční spotřeba je",
    exact_consumption: "Vaše roční spotřeba je",
    price_month: "Cena za měsíc",
    price_year: "Cena za rok",
    price_with_vat: "Cena je včetně DPH",
    select: "Objednat",
    our_suggestion: "NÁŠ TIP",
    czk_per_month: "Kč / měs.",
    czk_per_year: "Kč / rok",
    regulated_price: "Regulovaná část ceny\n(vč. distribuce)",
    pp_price: "Cena dodávky od\nPražské plynárenské",
    vat: "DPH",
    sum: "Celkem",
    not_registered: "Nejste registrován?",
    or: "nebo",
    contact_info: "Kontaktní údaje",
    payment_info: "Platební údaje",
    supply_point: "Odběrné místo",
    process_select: "Výběr situace",
    previous_customer: "Předchozí majitel",
    name: "Jméno",
    surname: "Příjmení",
    title_before: "Titul před",
    title_after: "Titul za",
    birthDate: "Datum narození",
    email: "E-mail",
    toEmail: "Na e-mail",
    toDeliveryAddress: "Poštou na zasílací adresu",
    phone: "Mobilní telefon",
    street: "Ulice",
    house_number: "Č. popisné",
    street_number: "Č. orientační",
    parcel_number: "Č. parcelní",
    registration_number: "Č. evidenční",
    city: "Město",
    zip: "PSČ",
    person_delivery_address_is_same: "Zasílací adresa je stejná jako adresa trvalého bydliště",
    company_delivery_address_is_same: "Zasílací adresa je stejná jako sídlo firmy",
    companyIn: "IČO",
    companyTin: "DIČ",
    companyName: "Název firmy",
    position: "Pozice ve firmě",
    monthly: "Měsíčně",
    quarterly: "Čtvrtletně",
    paymentsFrequency: "Frekvence plateb",
    paymentType: "Typ platby",
    bankTransfer: "Bankovním převodem",
    inkaso: "Přímým inkasem",
    sipo: "SIPO",
    depositAmount: "Výše záloh",
    yourBankAccount: "Váš bankovní účet",
    depositPaymentTypeForAll: "Nedoplatky a přeplatky platit stejně jako zálohy.",
    connectionNumber: "Spojovací číslo",
    fillDataMyself: "Údaje vyplním sám - <b>rychlejší sjednání smlouvy</b>",
    fillDataWithPpas: "Údaje vyplním společně s Pražskou plynárenskou",
    supplyPointAddressIsSame: "Adresa odběrného místa je stejná jako adresa trvalého bydliště",
    supplyPointIsAFlat: "Je odběrné místo byt?",
    previousContractTerminated: "Předchozí zákazník si ukončil smlouvu s původním dodavatelem sám",
    supplier: "Dodavatel",
    contractValidity: "Platnost smlouvy",
    contractValidityCertainTime: "Doba určitá",
    contractValidityIndefinite: "Doba neurčitá",
    contractValidityUnknown: "Nevím",
    contractValidityTerminated: "Původní smlouva již ukončena",
    contractValidTo: "Konec smlouvy",
    noticePeriod: "Výpovědní lhůta",
    months: "měsíce",
    months2: "měsíců",
    days: "dnů",
    flatNumber: "Vaše číslo bytu",
    floor: "Patro",
    gasAcc: "plyn",
    electricityAcc: "elektřinu",
    ean: "EAN",
    eanCode: "EAN kód",
    eic: "EIC",
    eicCode: "EIC kód",
    unknown: "Nevím",
    appliance: "spotřebič",
    consumptionLocation: "Lokalita odběru",
    commodity: "Komodita",
    electricity: "Elektřina",
    gas: "Plyn",
    selectedProduct: "Vybraný produkt",
    contractDuration: "Doba platnosti smlouvy",
    fullName: "Jméno a příjmení",
    permanentAddress: "Trvalé bydliště",
    deliveryAddress: "Zasílací adresa",
    supplyPointAddress: "Adresa odběrného místa",
    sendContractDraftTo: "Zaslání návrhu smlouvy",
    deposits: "Zálohy",
    arrears: "Nedoplatky",
    overpayments: "Přeplatky",
    sendInvoicesElectronically: "Zasílání faktur elektronicky",
    yearlyConsumption: "Roční spotřeba",
    flatAndFloor: "Číslo bytu a patro",
    currentSupplier: "Stávající dodavatel",
    endOfValidity: "Konec platnosti stávající smlouvy",
    until: "do",
    to: "k",
    month: "měsíčně",
    quarter: "čtvrtletně",
    count: "Počet",
    readingDate: "Datum odečtu",
    deviceId: "Číslo měřidla",
    consumption: "Výkon",
    actual_state: "Aktuální stav",
    invalid_transfer_data: "Zadané údaje neodpovídají",
    customerType: "Typ zákazníka",
    transferOnBehalf: "Přepis zařizuji pro jinou osobu",
    transferChange: "Změna zákazníka",
    transferDeath: "Úmrtí",
    transferType: "Důvod přepisu",
    transferCustomerType: "Typ nového zákazníka",
    iam: "Jsem",
    downloadTemplatePowerOfAttorney: "Stáhnout šablonu plné moci",
    downloadTemplatePowerOfAttorneyPrevious: "Stáhnout šablonu plné moci",
    downloadTemplateContractTermination: "Stáhnout šablonu žádosti",
    processAcquisition: "Změna dodavatele energií bez změny zákazníka",
    processTransferCustomer: "Změna dodavatele energií se změnou zákazníka",
    processTransferDeath: "Změna dodavatele energií se změnou zákazníka z důvodu úmrtí",
    previousPartnerType: "Typ zákazníka",
    downloadTemplate: "Stáhnout šablonu",
    downloadTemplateContractTerminationAnnounce: "Stáhnout šablonu oznámení",
    done: "Hotovo!",
    addressToggleOn: "Zpět na vyhledávání adres",
    addressToggleOff: "Chcete vyplnit adresu ručně?",
    callMeWidget: "Potřebuji poradit s výběrem produktu",
  },
  actions: {
    unknown_consumption: "Nevíte přesně?",
    known_consumption: "Vím přesně",
    choose: "Vyberte",
    calculate_savings: "Spočítat",
    more_about_the_price: "Více o ceně",
    confirm_signature: "Podepisuji",
    yes: "Ano",
    no: "Ne",
    signin: "Přihlásit",
    forgotten_password: "Zapomněli jste své heslo?",
    click_here: "Klikněte zde",
    send: "Odeslat",
    back_to_signin: "Zpět na přihlášení",
    go_back: "Zpět",
    goBackToForm: "Zpět do formuláře",
    continue: "Pokračovat",
    submit: "Potvrdit a odeslat",
    submit_and_continue: "Potvrdit a pokračovat",
    add_sp: "Chcete přidat jiné odběrné místo?",
    next: "Pokračovat",
    nextTransfer: "Pokračovat v přepisu",
    add_title_before: "Přidat titul před jméno",
    remove_title_before: "Odebrat titul před jménem",
    add_title_after: "Přidat titul za jméno",
    remove_title_after: "Odebrat titul za jménem",
    only_parcel_number: "Mám jen číslo parcely",
    only_registration_number: "Mám jen číslo evidenční",
    only_house_number: "Mám číslo popisné",
    drag_file: "Soubor přetáhněte sem",
    select_file_manually: "Vyberte soubor ručně",
    select_file: "Vyberte soubor",
    try_again: "Zkusit znovu",
    backToBeginning: "Zpět na domovskou stránku",
    backToMainWeb: "Zpět na web PPAS",
    anotherCommodity: "Chcete od nás odebírat i {commodity} nebo uzavřít další smlouvu?",
    whatAppliancesDoYouUse: "Jaké plynové spotřebiče používáte?",
    noticeNeeded: "Chcete přidat poznámku?",
    uploadLastInvoiceShort: "Nahrajte nám poslední fakturu.",
    create_contract: "Vytváříme smlouvu",
    please_be_patient: "Mějte chvilku strpení...",
    lastInvoiceUploaded: {
      value: "%form1 %1 %form2",
      rules: {
        form1: {
          defaultValue: "Nahráno",
          forms: [
            [[1], "Nahrána"],
            [[2, 3, 4], "Nahrány"],
          ],
        },
        form2: {
          defaultValue: "příloh",
          forms: [
            [[1], "příloha"],
            [[2, 3, 4], "přílohy"],
          ],
        },
      },
    },
    backToContact: "Zpět na kontaktní údaje",
    back: "Zpět",
    editData: "Upravit údaje",
    uploadLastInvoice: "Nahrajte nám poslední fakturu a zjednodušíte tím sjednání smlouvy.",
    close: "Zavřít",
    logout: "Odhlásit",
    showNext: "Zobrazit další",
    product: "produkt",
    products: "produkty",
    tryAgain: "Zkuste znovu",
    tryingAgain: "Zkouším ...",
    getBonus: "Sjednat další smlouvu a získat bonus až {bonus} Kč",
    newContract: "Sjednat další smlouvu",
    callMe: "Zavolejte mi",
  },
  mobileTitles: {
    welcome: "Spočítejte si, jakou cenu budete mít u nás",
    whatCommodity: "Jakou komoditu chcete odebírat?",
    yourYearConsumption: "Jaká je vaše roční spotřeba?",
    yourLocationGas: "Kde plyn odebíráte?",
    yourLocationElectricity: "Kde elektřinu odebíráte?",
    areYouInterested: "Máte zájem?",
    transferUnsupported:
      "Omlouváme se, ale funkce přepisu není k dispozici v mobilním zobrazení. Otevřete prosím odkaz z e-mailu v prohlížeči Vašeho počítače.",
  },
  titles: {
    productStub: "Potřebujete poradit s výběrem produktu?",
    leadModal: "Potřebujete poradit s výběrem produktu?",
    leadModalThanks: "Děkujeme za Váš zájem",
    currentReadingTooltip: "Uvádějte vždy hodnotu bez desetinných míst",
    bonusBox: "Snižte ještě více své náklady na energie",
    session_expired: "Platnost nabídky vypršela, prosím zkuste to znovu a vyberte si ze stávající nabídky produktů",
    sign_here: "Podepište se prosím",
    calculator_title: "Spočítejte si, jakou cenu budete mít u nás",
    your_year_consumption: "Jaká je vaše roční spotřeba?",
    your_location_gas: "Kde plyn odebíráte",
    your_location_electricity: "Kde elektřinu odebíráte",
    where_do_you_live: "Kde bydlíte?",
    where_do_you_want_to_buy_el: "Kde chcete elektřinu odebírat?",
    where_do_you_want_to_buy_gs: "Kde chcete plyn odebírat?",
    recommended_products: "Na základě vaší spotřeby vám doporučujeme tyto produkty:",
    pp_customer: "Mám zákaznický portál Pražské plynárenské?",
    forgotten_password: "Zapomněli jste své heslo?",
    contact_information: "Vaše údaje pro sjednání produktu",
    payment_information: "Jak si přejete platit?",
    electricitySupplyPointInformation: "Kde elektřinu odebíráte",
    gasSupplyPointInformation: "Kde a jak plyn odebíráte",
    permanent_address: "Adresa trvalého bydliště",
    delivery_address: "Zasílací adresa",
    company_address: "Sídlo firmy",
    supplyPointAddress: "Adresa odběrného místa",
    how_to_send_contract: "Jak chcete zaslat návrh smlouvy?",
    howToSendInvoices: "Jak chcete zasílat faktury?",
    competent_person: "Osoba jednající za firmu",
    setup_payments: "Nastavte si platby záloh",
    howToPayArrears: "Jak si přejete platit nedoplatky?",
    howToRefundOverpayments: "Kam si přejete vrátit přeplatky?",
    supplyPointData: "Údaje o odběrném místě",
    currentSupplier: "Stávající dodavatel",
    thankYou: "Děkujeme Vám za dokončení podkladů pro uzavření smlouvy, pro kterou jste si zvolili produkt\n{product}",
    uploadYourInvoice: "Nahrajte nám Vaší fakturu za {commodity}",
    gasUsage: "K čemu plyn používáte?",
    checkData: "Zkontrolujte si správnost údajů",
    checkPdfContract: "Zkontrolujte Vaší smlouvu a Plnou moc",
    basicInfo: "Základní údaje",
    contactInfo: "Kontaktní údaje",
    paymentInfo: "Platební údaje",
    supplyPoint: "Odběrné místo",
    error: "Moc nás to mrzí, něco se při odesílání pokazilo.",
    errorMultiple: "Tak tohle nevypadá dobře. Vraťte se prosím zítra a vše už určitě bude v pořádku.",
    transfer: "Přepis odběrného místa",
    checkOpmData: "Zkontrolujte údaje odběrného místa",
    transferDocuments: "Nahrajte doklad o vztahu k nemovitosti",
    transferDocumentsNew: "Doklad o vztahu k nemovitosti",
    powerOfAttorney: "Plná moc pro realizaci změny dodavatele",
    powerOfAttorneyCompany: "Plná moc osoby jednající za firmu",
    powerOfAttorneyNew: "Plná moc nového zákazníka",
    powerOfAttorneyPrevious: "Plná moc původního zákazníka",
    previousCustomer: "Předchozí zákazník",
    previousConsumption: "Stav měřidla",
    uploadPowerOfAttorney: "Nahrajte plnou moc",
    newCustomer: "- nový zákazník",
    fillOpmCode: "Zadejte kód odběrného místa",
    transferReason: "Zvolte důvod přepisu",
    transferInfo: "Pro výpočet vhodného produktu zadejte Vaše základní informace",
    crossSellSupplyPointEdit: "Základní informace o odběrném místě",
    supplyPointPreviousState: "Předchozí stav měřidla",
    uploadRequiredAttachments: "Nahrajte povinné přílohy",
    transferCheckPrevious: "Zadejte alespoň jeden údaj předchozího zákazníka pro ověření",
    contractTerminationRequestPower: "Žádost o ukončení smlouvy o sdružených službách dodávky elektřiny ze sítí nízkého napětí",
    contractTerminationRequestGas: "Žádost o ukončení smlouvy o sdružených službách dodávky zemního plynu",
    deathCertificate: "Doklad o úmrtí původního zákazníka",
    processSelect: "Zvolte jakou situaci řešíte",
    currentCustomer: "Předchozí zákazník",
    requiredDocuments: "Povinné přílohy",
    operatorDocument: "Podklady pro provozovatele distribuční soustavy",
  },
  placeholders: {
    searchAddress: "Začněte psát adresu, např. Národní 37/38",
    fill_one_the_fields: "vyplňte jedno z polí",
    nameLabel: "Jméno a příjmení",
    phoneLabel: "Mobilní telefon",
    email: "E-mail",
    password: "Heslo",
    select_partner: "Vyberte z vašich zákaznických účtů",
    select_account: "Vyberte z vašich smluvní účtů",
    birth_date: "např. 1.12.1980",
    phone: "např. 731 123 456",
    parcel_number: "např. 676/1 nebo 1006760010",
    house_number: "číslo před lomítkem",
    street_number: "číslo za lomítkem",
    bankPrefix: "Předčíslí",
    bankAccount: "Číslo účtu",
    bankCode: "Kód banky",
    contractValidTo: "např. {date}",
    noticePeriod: "např. 20",
    ean: "Zadejte zbylých 12 čísel",
    eic: "Zadejte zbylých 12 znaků",
    note: "Vaše poznámka...",
  },
  texts: {
    productStub: "Zanechte nám na sebe kontakt a my Vám rádi pomůžeme vybrat produkt na míru.",
    leadModalThanksText:
      "Děkujeme Vám za zájem o naše služby. Naši operátoři Vás kontaktují a pomohou s výběrem do 2 pracovních dnů.",
    leadModalText: "Zanechte nám na sebe kontakt a my Vám rádi pomůžeme vybrat produkt na míru.",
    leadModalAgreement:
      'Uděluji <a href="https://www.ppas.cz/sites/default/files/pages/gdpr_marketingovy_souhlas_ocr.pdf" target="_new">Marketingový souhlas</a> za účelem zpracování osobních údajů pro nabídku produktů a služeb koncernu Pražská&nbsp;plynárenská, a.s.',
    bonusBoxText:
      "<b>Uzavřete s námi další smlouvu na elektřinu nebo plyn a získejte bonus až {bonus} Kč.</b>\nSjednání nové smlouvy bude jednodušší, některé údaje z již založené smlouvy budou předvyplněny.",
    noBonusBoxText: "Sjednání nové smlouvy bude jednodušší, některé údaje z již založené smlouvy budou předvyplněny.",
    gas_appliances_memo:
      "Je třeba vyplnit výkon spotřebičů uvedených na Smlouvě o připojení k distribuční soustavě předchozího zákazníka. Na základě těchto údajů se vystaví nová smlouva, prosíme, věnujte této části zvýšenou pozornost.",
    login_description:
      "Přihlášením se k Vašemu účtu se předvyplní nová smlouva kontaktními údaji, které zde máte uloženy. Tuto možnost zvolte pouze v případě, kdy novou smlouvu chcete uzavřít na stejnou fyzickou či právnickou osobu, pod kterou se do portálu přihlašujete.",
    signature_confirmation_tooltip: "Kliknutím podepíšete oba dokumenty",
    processAcquisition_help: "Mění se pouze dodavatel, zákazník zůstává stejný",
    processTransferCustomer_help:
      "Původní zákazník měl smlouvu u jiného dodavatele\na nový zákazník přechází k Pražské plynárenské",
    processTransferDeath_help:
      "Původní zákazník měl smlouvu u jiného dodavatele a zemřel,\nnový zákazník přechází k Pražské plynárenské",
    signature_help:
      "Podpis proveďte tahem spolu se zmáčknutým levým tlačítkem myši nebo tahem prstu na vašem mobilním zařízení.\nNemějte obavy, pokud podpis není úplně dokonalý. Podepište se co nejblíže svému podpisu vlastní rukou.",
    yourEstimatedConsuption: "Vaše předpokládaná spotřeba",
    offer_is_valid: "Nabídka je platná k dnešnímu dni a ke konkrétní výši spotřeby.",
    insert_email: "Napište nám svůj e-mail a my vám rádi pomůžeme.",
    thankYouNote:
      "Na Vámi uvedenou e-mailovou adresu zasíláme shrnutí zadané smlouvy,\nzkontrolujeme správnost všech podkladů a zahájíme Vámi zvolený proces.\nProsíme, vyčkejte na další komunikaci z naší strany.",
    anotherCommodityTooltip: "<b>Sjednání bude nyní snazší</b>, některé údaje budou předvyplněny.",
    copyright: "©2002-2024 Pražská plynárenská , a.s",
    allowedFiles: "Povolené formáty .pdf, .png a .jpg. Soubor nesmí mít více než {size}.",
    eanOnYourContract: "Snadno naleznete na vaší faktuře.",
    contactHeader: "Pokud vás zaujala naše nabídka zanechte nám vaše základní údaje a my se vám ozveme.",
    thankYouNoteShort: "Děkujeme za kontakt, náš tým se Vám ozve co nejdříve.",
    formIsClosable: "Celý formulář nyní můžete zavřít.",
    transferDocument:
      "Podklady budou požity pro ukončení smlouvy původního zákazníka se stávajícím dodavatelem. Přiložte prosím kombinaci podkladů:<br /><ul><li><b>kupní smlouva nového zákazníka</b> nebo <b>darovací smlouva</b> (obojí mezi původním a novým zákazníkem), nebo</li><li><b>nájemní smlouva nového zákazníka</b> v kombinaci s <b>předávacím protokolem</b> nebo <b>dokladem o ukončení nájemní smlouvy původního zákazníka</b></li></ul>",
    transferDocuments: "Může to být např. <b>Nájemní smlouva</b>, <b>Kupní smlouva</b> nebo <b>Výpis z katastru nemovitostí</b>.",
    transferDeathDocument: "Může to být např. <b>Usnesení o dědictví</b> nebo <b>Výpis z katastru nemovitostí</b>.",
    confirmContract:
      'Souhlasím se <a href="https://www.ppas.cz/sites/default/files/faq/dokumenty/PP-VOP-9-001-22_V%C5%A1eobecn%C3%A9Obchodni_podminky_od_1.1.2022.pdf" target="_new">Všeobecnými obchodními podmínkami</a>, se <a href="https://www.ppas.cz/sites/default/files/pages/imz.pdf" target="_new">zpracováním osobních údajů</a>, s <a href=https://www.ppas.cz/ceniky target="_new">Ceníkem produktu</a> a s <a href="https://www.ppas.cz/sites/default/files/faq/dokumenty/PP-25-2-003-22%20Cen%C3%ADk%20nadstandardn%C3%ADch%20slu%C5%BEeb_0.pdf" target="_new">Ceníkem nadstandartních služeb</a>.',
    marketingAgreement:
      'Uděluji <a href="https://www.ppas.cz/sites/default/files/pages/gdpr_marketingovy_souhlas_ocr.pdf" target="_new">Marketingový souhlas</a> za účelem zpracování osobních údajů pro nabídku produktů a služeb koncernu Pražská plynárenská, a.s. (nepovinné)',
    confirmValidData:
      "Prohlašuji, že mnou uvedené údaje jsou pravdivé a souhlasím s uzavřením smlouvy za výše uvedených podmínek.",
    contractTerminationDocument:
      "<strong>Žádost o ukončení smlouvy</strong> je potřeba vyplnit a podepsat původním zákazníkem. V případě chybějících údajů nebo podpisu nelze převod zpracovat a bude Vám vrácen zpět k doplnění.<br /><br />Pokud není žádost o ukončení smlouvy podepsána původním zákazníkem, doložte k ní <strong>Oznámení vlastníka nemovitosti o trvalém opuštění odběrného místa zákazníkem.</strong>",
    contractTerminationDeathDocument:
      "<strong>Žádost o ukončení smlouvy</strong> je potřeba vyplnit bez podpisu, v případě chybějících ostatních údajů nelze převod zpracovat a bude Vám vrácen zpět k doplnění.",
    contactCustomerService: "Kontaktujte prosím zákaznickou linku",
    deathCertificate: "Přiložte prosím <b>úmrtní list</b>, <b>notářský zápis</b> nebo <b>lékařskou zprávu o úmrtí</b>.",
    paymentsTooltip: "Změnu záloh můžete žádat na zákaznickém portále po uskutečnění převodu.",
    customerNumbers:
      "<b>Bezplatná linka: 267 175 333</b><br /><br />E-mail: callcentrum@ppas.cz<br /<br />Provozní doba: Po-Pá, 8-18 hod.",
    contractTermination:
      "Pokud je smlouva u předchozího dodavatele již ukončena, uveďte prosím k jakému datu, abychom mohli realizovat navazující změnu dodavatele.",
    contractTerminationChangeCustomer:
      "Pokud je smlouva u předchozího dodavatele již ukončena, uveďte prosím k jakému datu, abychom mohli realizovat navazující změnu dodavatele se změnou zákazníka.",
  },
  errors: {
    // Base error messages for validators]
    // Do NOT modify keys!
    isNotEmpty: "Vyplňte prosím",
    isNumber: "Vyplňte číslo",
    isGreaterThan: "Mělo by být větší než {min}",
    isGreaterOrEqualTo: "Mělo by být alespoň {min}",
    isEmail: "Neplatný email",
    isSipo: "Číslo SIPO není platné",
    isBankAccount: "Bankovní účet není platný",
    isDate: "Datum není platné",
    dateIsInPast: "Datum musí být v minulosti",
    isPhoneNumber: "Neplatné telefonní číslo",
    isEIC: "Kód EIC není platný",
    isEAN: "Kód EAN není platný",
    isZip: "PSČ není platné",
    isStreetNumber: "Číslo není platné",
    isParcelNumber: "Číslo není platné",
    isHouseNumber: "Číslo není platné",
    isICO: "Formát IČO není správný",
    isDIC: "Formát DIČ není správný",
    default: "Neplatná hodnota",
    isNotChecked: "Souhlas je povinný",
    isLength: "Maximální délka je {max} znaků",
    isIn: "Není v zadaném rozsahu",
    isComplex: "Podpis není dostatečný, prosím podepište se znova",
    isValidSize: "Podpis je příliš malý",
    inFuture: "Datum musí být v budoucnosti",

    // Custom messages
    selectAddressFromList: "Vyberte adresu z nabídky",
    regionRequired: "Vyberte kraj",
    fileIsTooLarge: "Soubor přesahuje limit {size}.",
    unsupportedFileType: "Nepodporovaný typ souboru.",
    uploadError: "Nastala chyba při nahrávání.",
    isAdult: "Osoba musí být starší 18 let",
    isEmailOrPhone: "Telefon nebo email je povinný",
    loginError: "Přihlášení se nezdařilo",
    fileIsRequired: "Příloha je povinná",
    isIcoOrBirthDate: "IČO nebo datum narození je povinné",
    invalidBankCode: "Neplatný kód banky",
    validToInFuture: "Datum musí být vyšší o {days} dnů než je aktuální",
    noticePeriodTooLong: "Přesahuje konec smlouvy",
    matchesWithName: "Příjmení je shodné se Jménem",
    matchesWithSurname: "Jméno je shodné s Příjmením",
    integerOnly: "Vyplňte celé číslo",
    invalidName: "Jméno obsahuje nepovolené znaky",
    invalidSurname: "Příjmení obsahuje nepovolené znaky",
    corporateConsumptionReached:
      "Zkontrolujte prosím výši zadávané spotřeby. Při zájmu o&nbsp;uzavření smlouvy s&nbsp;roční spotřebou vyšší než 630&nbsp;MWh nás prosím kontaktujte na emailu <a href='mailto:{email}'>{email}</a>",
  },
  regions: {
    [REGIONS.praha]: "Praha",
    [REGIONS.jihocesky]: "Jihočeský kraj",
    [REGIONS.jihomoravsky]: "Jihomoravský kraj",
    [REGIONS.karlovarsky]: "Karlovarský kraj",
    [REGIONS.kralovehradecky]: "Královehradecký kraj",
    [REGIONS.liberecky]: "Liberecký kraj",
    [REGIONS.moravskoslezsky]: "Moravskoslezský kraj",
    [REGIONS.olomoucky]: "Olomoucký kraj",
    [REGIONS.pardubicky]: "Pardubický kraj",
    [REGIONS.plzensky]: "Plzeňský kraj",
    [REGIONS.stredocesky]: "Středočeský kraj",
    [REGIONS.ustecky]: "Ústecký kraj",
    [REGIONS.vysocina]: "Vysočina",
    [REGIONS.zlinsky]: "Zlínský kraj",
  },
  contractDuration: {
    0: "Doba neurčitá",
    12: "12 měsíců",
    24: "24 měsíců",
    36: "36 měsíců",
  },
  months: {
    1: "Leden",
    2: "Únor",
    3: "Březen",
    4: "Duben",
    5: "Květen",
    6: "Červen",
    7: "Červenec",
    8: "Srpen",
    9: "Září",
    10: "Říjen",
    11: "Listopad",
    12: "Prosinec",
    [UNKNOWN]: "Nevím",
  },
  ares: {
    loading: "Načítáme data z ARES",
    loaded: "Data z ARES načtena",
    error: "Došlo k chybě při načítání dat z ARES",
    prompt: "Vyplňte IČO pro načtení dat z ARES",
  },
};
