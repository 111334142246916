import React from "react";

import locs from "../../localization";

import Input from "../../components/Input";
import Title from "../../components/Title";
import Column from "../../containers/Column";
import dateFormatter from "../../formatters/date";
import Date from "../../components/Date";
import formValidation from "../../validations/formValidation";
import isDate from "../../validations/isDate";
import isNotEmpty from "../../validations/isNotEmpty";
import dateIsInPast from "../../validations/dateIsInPast";
import isNumber from "../../validations/isNumber";
import { Field, WrappedFieldProps } from "redux-form";
import Help from "../../components/Help";
import picture from "../../../img/meter.png";
import styles from "./styles.module.scss";

export interface PreviousConsumptionProps {
  isElectricity: boolean;
  disabled?: boolean;
  theme?: any;
  supplyPointCode?: string;
  valueNt?: boolean;
}

export default function PreviousConsumption({
  isElectricity,
  disabled = false,
  theme,
  supplyPointCode,
  valueNt = false,
  input: { name },
}: PreviousConsumptionProps & WrappedFieldProps) {
  const tooltip = (
    <div className={styles.tooltipContainer}>
      <Help>
        <div className={styles.tooltip}>
          {!isElectricity && (
            <div className={styles.imageContainer}>
              <img src={picture} />
            </div>
          )}
          <div className={styles.instructions}>
            <span>{locs("titles.currentReadingTooltip")}</span>
          </div>
        </div>
      </Help>
    </div>
  );

  return (
    <>
      {supplyPointCode && (
        <>
          <Title name={locs("titles.supplyPointData")} tag="h3" />
          <Column weights={["1_11", 1]} columns={2}>
            <div>
              <Input
                disabled
                input={{ value: locs(isElectricity ? "labels.electricity" : "labels.gas") } as any}
                theme={theme}
                label={locs("labels.commodity")}
                meta={{} as any}
              />
            </div>
          </Column>
          <Column weights={["1_11", 1]} columns={2}>
            <div>
              <Input
                disabled
                input={{ value: supplyPointCode || "" } as any}
                theme={theme}
                label={locs(isElectricity ? "labels.ean" : "labels.eic")}
                meta={{} as any}
              />
            </div>
          </Column>
        </>
      )}

      <Title name={locs("titles.previousConsumption")} tag="h3" />
      <Column weights={["1_11", 1]} columns={2} withTooltip>
        <Field
          name={`${name}.value`}
          component={Input}
          type="number"
          props={{
            theme,
            label: locs(isElectricity ? "labels.high_tariff" : "labels.actual_state"),
            suffix: locs(isElectricity ? "labels.kw" : "labels.m3"),
            disabled,
          }}
          validate={
            !disabled && [formValidation(isNotEmpty), formValidation(isNumber({ integer: true }), locs("errors.integerOnly"))]
          }
        />
        {tooltip}
      </Column>
      {isElectricity && valueNt && (
        <Column weights={["1_11", 1]} columns={2} withTooltip>
          <Field
            name={`${name}.valueNt`}
            component={Input}
            type="number"
            props={{
              theme,
              label: locs("labels.low_tariff"),
              suffix: locs("labels.kw"),
              disabled,
            }}
            validate={!disabled && [formValidation(isNumber({ integer: true }), locs("errors.integerOnly"))]}
          />
          {tooltip}
        </Column>
      )}
      <Column weights={["1_11", 1]} columns={2}>
        <Field
          name={`${name}.deviceId`}
          component={Input}
          props={{
            theme,
            label: locs("labels.deviceId"),
            disabled,
          }}
          validate={!disabled && [formValidation(isNotEmpty)]}
        />
      </Column>
      <Column weights={["1_11", 1]} columns={2}>
        <Field
          name={`${name}.date`}
          component={Date}
          normalize={dateFormatter}
          validate={!disabled && [formValidation(isNotEmpty), formValidation(isDate), formValidation(dateIsInPast)]}
          props={{
            theme,
            label: locs("labels.readingDate"),
            disabled,
            viewMode: "days" as "days",
          }}
        />
      </Column>
    </>
  );
}
