import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

const BASE_HEIGHT = 25;

const Line = styled.div`
  animation: expand 1s ease-in-out infinite;
  border-radius: 10px;
  display: inline-block;
  transform-origin: center center;
  margin: 0 ${props => 5 * (props.height / BASE_HEIGHT)}px;
  width: 2px;
  height: ${props => props.height}px;
  background: #015996;
`;

Line.propTypes = {
  height: PropTypes.number,
};

Line.defaultProps = {
  height: BASE_HEIGHT,
};

const Loader = styled.div`
  display: inline-flex;

  > *:nth-child(2) {
    animation-delay: 180ms;
  }

  > *:nth-child(3) {
    animation-delay: 360ms;
  }

  > *:nth-child(4) {
    animation-delay: 540ms;
  }

  @keyframes expand {
    0% {
      transform: scale(1);
    }

    25% {
      transform: scale(2);
    }
  }
`;

function InlineLoader({ height, title, subTitle }) {
  return (
    <>
      <Loader>
        <Line height={height} />
        <Line height={height} />
        <Line height={height} />
        <Line height={height} />
      </Loader>
      {title && <div className={styles.title}>{title}</div>}
      {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
    </>
  );
}

InlineLoader.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

InlineLoader.defaultProps = {
  height: BASE_HEIGHT,
};

export default InlineLoader;
