import React from "react";
import { FieldArray, WrappedFieldProps } from "redux-form";
import locs from "../../localization";
import Title from "../../components/Title";

import GasAppliances from "./GasAppliances";

import styles from "./styles.module.scss";
import { Appliance as ApplianceEnum } from "../../constants/interfaces";
import isGasUsageNotEmpty from "../../validations/isGasUsageNotEmpty";
import formValidation from "../../validations/formValidation";

interface GasUsageProps {
  disabled?: boolean;
  partnerValue: number;
  appliances: ApplianceEnum[];
  consumptionRequired?: boolean;
}

export default function GasUsage(props: GasUsageProps & WrappedFieldProps) {
  const { input, appliances, disabled = false, partnerValue, consumptionRequired } = props;

  const fieldName = name => `${input.name}.${name}`;

  return (
    <div className={styles.currentSupplier}>
      <Title name={locs("actions.whatAppliancesDoYouUse")} tag="h3" className={styles.title} />
      <FieldArray
        name={fieldName("appliances")}
        component={GasAppliances as any}
        props={{
          appliances: appliances.filter(x => x.externalPartnerIds.indexOf(partnerValue) >= 0),
          disabled,
          consumptionRequired: consumptionRequired,
        }}
        validate={[formValidation(isGasUsageNotEmpty)]}
        rerenderOnEveryChange
      />
    </div>
  );
}
