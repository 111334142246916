import React from "react";
import { connect } from "react-redux";
import { LeadSource, openLeadDialog } from "../../actions/leadDialog";
import styles from "./styles.module.scss";
import locs from "../../localization";

interface CallMeWidgetProps {
  openLeadDialog: (source: LeadSource) => void;
}

const mapDispatchToProps = {
  openLeadDialog,
};

const CallMeWidget = ({ openLeadDialog }: CallMeWidgetProps) => {
  return (
    <div
      className={styles.floater}
      onClick={() => {
        openLeadDialog("widget");
      }}>
      <div className={styles.inner}>{locs("labels.callMeWidget")}</div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(CallMeWidget);
