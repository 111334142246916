import React from "react";
import Column from "../../containers/Column";
import { formatEnumerationData } from "../../helpers";
import locs from "../../localization";
import Input from "../../components/Input";
import Title from "../../components/Title";

import styles from "./styles.module.scss";
import paymentsTheme from "../Payments/theme.module.scss";
import theme from "./theme.module.scss";
import { TitlePrefix, TitleSuffix } from "../../constants/interfaces";
import { Field, WrappedFieldProps } from "redux-form";
import { PARTNER_COMPANY } from "../../constants";
import PartnerType from "../Calculator/PartnerType";
import Select from "../../components/Select";
import formValidation from "../../validations/formValidation";
import isNotEmpty from "../../validations/isNotEmpty";
import isLength from "../../validations/isLength";
import isICO from "../../validations/isICO";
import { isDIC } from "../../validations/isDIC";

interface PreviousCustomerProps {
  disabled?: boolean;
  previousPartner?: number; // If not prefilled partner type selector will be shown
  death?: boolean;
  titleSuffixes: TitleSuffix[];
  titlePrefixes: TitlePrefix[];
}

const PreviousCustomer = ({
  titlePrefixes,
  titleSuffixes,
  disabled = false,
  death = false,
  previousPartner,
  input: { value: inputValue = {}, name },
}: PreviousCustomerProps & WrappedFieldProps) => {
  let partnerSelector;

  if (!previousPartner && !death) {
    partnerSelector = (
      <PartnerType
        name={`${name}.previousCustomer.previousPartnerValue`}
        label={locs("labels.previousPartnerType")}
        theme={paymentsTheme}
      />
    );
  }

  if (previousPartner === PARTNER_COMPANY || inputValue.previousCustomer?.previousPartnerValue === PARTNER_COMPANY) {
    return (
      <div className={styles.contact_container}>
        <Title name={locs("titles.previousCustomer")} tag="h3" />
        <Column weights={["1_11", 1]}>{partnerSelector}</Column>
        <Column weights={["1_11", 1]}>
          <div>
            <Field
              name={`${name}.previousCustomer.name`}
              component={Input}
              props={{
                disabled,
                theme,
                label: locs("labels.companyName"),
              }}
              validate={!disabled && [formValidation(isNotEmpty), formValidation(isLength({ max: 40 }))]}
            />
          </div>
          <div>
            <Field
              name={`${name}.previousCustomer.in`}
              component={Input}
              props={{
                disabled,
                theme,
                label: locs("labels.companyIn"),
              }}
              validate={!disabled && [formValidation(isNotEmpty), formValidation(isICO)]}
            />
          </div>
        </Column>
        <Column columns={2} weights={["1_11", 1]}>
          <Field
            name={`${name}.previousCustomer.tin`}
            component={Input}
            props={{
              disabled,
              theme,
              label: locs("labels.companyTin"),
            }}
            validate={!disabled && [formValidation(isLength({ max: 60 })), formValidation(isDIC)]}
          />
        </Column>
      </div>
    );
  }

  return (
    <div className={styles.contact_container}>
      <Title name={locs("titles.previousCustomer")} tag="h3" />
      <Column weights={["1_11", 1]}>{partnerSelector}</Column>
      <Column weights={["1_11", 1]}>
        <div>
          <Field
            name={`${name}.previousCustomer.person.name`}
            component={Input}
            props={{
              disabled,
              theme,
              label: locs("labels.name"),
            }}
            validate={!disabled && [formValidation(isNotEmpty), formValidation(isLength({ max: 40 }))]}
          />
        </div>
        <div>
          <Field
            name={`${name}.previousCustomer.person.surname`}
            component={Input}
            props={{
              disabled,
              theme,
              label: locs("labels.surname"),
            }}
            validate={!disabled && [formValidation(isNotEmpty), formValidation(isLength({ max: 40 }))]}
          />
        </div>
      </Column>
      <Column weights={["1_11", 1]}>
        <div>
          <Field
            name={`${name}.previousCustomer.person.titlePrefixId`}
            component={Select}
            props={{
              disabled,
              theme,
              showBlankOption: true,
              data: formatEnumerationData(titlePrefixes, "desc"),
            }}
            label={locs("labels.title_before")}
          />
        </div>
        <div>
          <Field
            name={`${name}.previousCustomer.person.titleSuffixId`}
            component={Select}
            props={{
              disabled,
              theme,
              showBlankOption: true,
              data: formatEnumerationData(titleSuffixes, "desc"),
            }}
            label={locs("labels.title_after")}
          />
        </div>
      </Column>
    </div>
  );
};

export default PreviousCustomer as React.FunctionComponent<PreviousCustomerProps>;
