import Moment from "moment";
import isDate from "./isDate";

interface Settings {
  unitOfTime: Moment.unitOfTime.Diff;
  value: number;
  precise?: boolean;
  dateFormat: string;
}

const defaults: Settings = {
  unitOfTime: "years",
  value: 1,
  precise: false,
  dateFormat: "D.M.YYYY",
};

export default function isOlderThanOrEqual(options: Partial<Settings> = {}) {
  const settings = {
    ...defaults,
    ...options,
  };

  function olderThanOrEqualValidator(value) {
    if (!value) return true;
    if (!isDate(value)) return false;
    const date = Moment.isMoment(value) ? value : Moment(value, settings.dateFormat, true);
    return Moment().diff(date, settings.unitOfTime, settings.precise) >= settings.value;
  }

  return Object.assign(olderThanOrEqualValidator, settings);
}
