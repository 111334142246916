import { CLOSE_LEAD_DIALOG, LEAD_SUCCESSFULLY_SUBMITTED, OPEN_LEAD_DIALOG } from "../constants/actions";

const initialState = {
  isOpen: false,
};

type LeadAction = typeof OPEN_LEAD_DIALOG | typeof CLOSE_LEAD_DIALOG | typeof LEAD_SUCCESSFULLY_SUBMITTED;

const leadReducer = (state = initialState, action: { type: LeadAction }) => {
  switch (action.type) {
    case OPEN_LEAD_DIALOG:
      return { ...state, isOpen: true, shown: true }; // Saving source is in form reducer to save it there
    case CLOSE_LEAD_DIALOG:
      return { ...state, isOpen: false };
    case LEAD_SUCCESSFULLY_SUBMITTED:
      return { ...state, submitted: true };
    default:
      return state;
  }
};

export default leadReducer;
