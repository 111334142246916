import React from "react";
import Link from "../../components/Link";
import Title from "../../components/Title";
import locs from "../../localization";
import styles from "./styles.module.scss";

export interface HeaderProps {
  title: string;
  editData: () => void;
}

export default function header(props: HeaderProps) {
  const { title, editData } = props;

  return (
    <div className={styles.header}>
      <Title name={title} tag="h3" />
      <div>{editData && <Link label={locs("actions.editData")} onClick={editData} />}</div>
    </div>
  );
}
