import React from "react";
import classNames from "classnames";
import Datetime, { ViewMode } from "react-datetime";
import layout from "../UI/styles.module.scss";
import styles from "./styles.module.scss";
import inputStyles from "../Input/styles.module.scss";
import { seleniumClass } from "../../helpers";
import { WrappedFieldProps } from "redux-form";

export interface DateProps {
  label?: string;
  diabled?: string;
  viewMode?: ViewMode;
  isValidDate?: (currentDate: any, selectDate: any) => boolean;
  theme?: {
    form_control?: string;
    baseContainer?: string;
    input?: string;
    inputContainer?: string;
    label?: string;
  };
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  autoComplete?: string;
}

export default function Date({
  meta,
  theme = {},
  input,
  placeholder,
  disabled = false,
  required = false,
  viewMode,
  isValidDate,
  label,
  autoComplete,
}: DateProps & WrappedFieldProps) {
  const isInvalid = () => meta.invalid && meta.touched;

  const baseContainerClassNames = classNames(layout.form_control, theme.form_control, styles.baseContainer, theme.baseContainer);
  const inputClassNames = classNames(theme.input, inputStyles.input, { [inputStyles.inputInvalid]: isInvalid() });
  const inputContainerClassNames = classNames(theme.inputContainer, inputStyles.inputContainer);
  const labelClassNames = classNames(theme.label, inputStyles.label);

  const dateProps = {
    ...input,
    value: input.value,
    className: styles.date_picker,
    inputProps: {
      id: input.name,
      placeholder,
      disabled,
      required,
      autoComplete,
      className: inputClassNames,
    },
    dateFormat: "D.M.YYYY",
    timeFormat: false,
    locale: "cs",
    closeOnSelect: true,
    initialViewMode: viewMode,
    isValidDate,
  };

  return (
    <div className={baseContainerClassNames}>
      <label className={seleniumClass(input)} htmlFor={input.name}>
        <div className={labelClassNames}>{label}</div>
      </label>
      <div className={inputContainerClassNames}>
        <Datetime {...dateProps} />
        {isInvalid() && <div className={inputStyles.errorMessage}>{meta.error}</div>}
      </div>
    </div>
  );
}
